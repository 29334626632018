import Box, { BoxProps } from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CheckboxStyle,
  IconArrowDownComponent,
  IconCrossComponent,
  IconSearchComponent,
  IconWarningComponent,
  IconsSize,
  IconsStyle,
  LBTCheckbox,
  LBTListItem,
  LBTProgressSpinner,
  LBTTextField,
} from '../..';
import { COLORS } from '../../utils/Colors';
import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  SelectProps,
  Stack,
} from '@mui/material';
import LBTChip from '../Chip';
import LBTLabel from '../Label';

type ItemType = {
  is_pinned?: boolean;
  description?: string;
  id: number | string | null;
  name: number | string;
  [x: string]: any;
};

export type LBTAutocompleteProps = SelectProps & {
  items: ItemType[];
  handleChange: (e: any) => void;
  hasFullWidth?: boolean;
  helperText?: string;
  datatestid?: string;
  getItems: (name: string) => Promise<void>;
  isLoading?: boolean;
  hasAsterisk?: boolean;
};

const StyledAutocomplete = styled(Autocomplete<ItemType>)<
  SelectProps & {
    helperText?: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ helperText, datatestid, ...props }) => ({
  helperText,
  datatestid,
  'data-track': props['data-track'],
  '& .MuiAutocomplete-root': {
    height: '52px',
  },
  '& .MuiInputBase-root': {
    minHeight: '52px',
    height: 'auto',
    backgroundColor: props.disabled
      ? `${COLORS.getInstance().BW_GREYS_IPERLIGHT} !important`
      : undefined,
  },
  '& .MuiFormControl-root': {
    minHeight: '52px',
    height: 'auto',
  },
}));

const StyledIconComponentBox = styled(Box)<BoxProps>(() => ({
  width: '20px',
  height: '20px',
  marginLeft: '8px',
}));

const LBTAutocomplete: React.FC<LBTAutocompleteProps> = ({
  items,
  handleChange,
  helperText,
  datatestid = '',
  variant = 'outlined',
  value,
  error,
  label,
  required,
  multiple,
  disabled,
  hasFullWidth = true,
  getItems,
  isLoading,
  hasAsterisk = true,
  ...props
}: LBTAutocompleteProps) => {
  const sortedItems: ItemType[] = items.slice().sort((a, b) => {
    if (a.is_pinned && !b.is_pinned) return -1;
    if (!a.is_pinned && b.is_pinned) return 1;
    return 0;
  });
  const [inputText, setInputText] = useState<string>(
    (sortedItems.find(item => item.id === value)?.name as string | undefined) ??
      '',
  );
  const getCurrentItem = () => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value) && multiple) {
        return sortedItems.filter(item => value.includes(item.id));
      }
      return sortedItems.find(item => item.id === value);
    }
    return null;
  };
  const shrink =
    !!inputText ||
    (multiple
      ? !!(value as any[])?.length && (value as any[]).length > 0
      : value !== null && value !== undefined);
  const currentItem = getCurrentItem();

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: ItemType) => option.name as string,
  });

  useEffect(() => {
    getItems(inputText);
  }, [inputText]);

  useEffect(() => {
    if (!Array.isArray(currentItem))
      setInputText(
        (sortedItems.find(item => item.id === value)?.name as
          | string
          | undefined) ?? '',
      );
  }, [value, currentItem]);

  const hasSearchIcon = () => {
    if (Array.isArray(value)) {
      return inputText === '' && !(value as any[]).length && multiple;
    }
    return false;
  };

  return (
    <FormControl fullWidth={hasFullWidth}>
      <InputLabel
        sx={{
          paddingLeft: hasSearchIcon() ? '28px' : '0',
        }}
        required={required && hasAsterisk}
        shrink={shrink}
      >
        {label}
      </InputLabel>
      <StyledAutocomplete
        size="small"
        options={[
          ...(!multiple && !required
            ? [{ name: '-', label: '-', id: null, desciption: undefined }]
            : []),
          ...sortedItems.map(item => ({
            name: item.name,
            label: item.name,
            id: item.id,
            desciption: item.desctiption,
          })),
        ]}
        onChange={(event: any, newValue: any) => {
          if (Array.isArray(newValue)) {
            const array = newValue.map(val => {
              return val?.id;
            });
            handleChange(array ?? null);
            setInputText('');
          } else {
            handleChange(newValue?.id ?? null);
            setInputText(newValue?.name ?? '');
          }
        }}
        onClose={() =>
          setInputText(
            (sortedItems.find(item => item.id === value)?.name as
              | string
              | undefined) ?? '',
          )
        }
        inputValue={inputText}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              boxShadow: `0px 1px 8px 0px rgba(0, 0, 0, 0.12),
                          0px 3px 4px 0px rgba(0, 0, 0, 0.14),
                          0px 3px 3px -2px rgba(0, 0, 0, 0.20);`,
            }}
          >
            {children}
          </Paper>
        )}
        value={
          currentItem
            ? Array.isArray(currentItem)
              ? currentItem.map(item => {
                  return {
                    name: item.name,
                    label: item.name,
                    id: item.id,
                    desciption: item.description,
                  };
                })
              : {
                  name: currentItem.name,
                  label: currentItem.name,
                  id: currentItem.id,
                  desciption: currentItem.description,
                }
            : undefined
        }
        fullWidth
        popupIcon={
          <IconArrowDownComponent
            size={IconsSize.MEDIUM}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().BLACK}
          />
        }
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        style={{
          height: 'auto',
        }}
        disabled={disabled}
        filterOptions={filterOptions}
        renderOption={(props, option) => {
          if (isLoading) {
            if (((props as any)['data-option-index'] as number) === 0)
              return (
                <LBTProgressSpinner
                  sx={{ margin: '8px', marginLeft: '20px' }}
                  size={30}
                  thickness={4}
                />
              );
            return null;
          }
          return (
            <MenuItem
              value={option.id}
              selected={
                multiple && Array.isArray(value)
                  ? value.indexOf(option.id) > -1
                  : option.id === value
              }
              key={option.id}
              sx={{
                borderTopWidth:
                  ((props as any)['data-option-index'] as number) !== 0
                    ? '1px'
                    : 0,
                borderTopColor: COLORS.getInstance().BW_GREYS_JET_BLACK_LIGHT,
                borderTopStyle: 'solid',
                '&:hover': {
                  backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT} !important`,
                },
                '&.Mui-selected': {
                  backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT} !important`,
                },
              }}
              {...props}
            >
              {multiple && Array.isArray(value) && (
                <LBTCheckbox
                  variant={CheckboxStyle.PRIMARY}
                  checked={value.indexOf(option.id) > -1}
                  sx={{ marginRight: '18px', padding: '11px' }}
                  onChange={() => {}}
                />
              )}
              <LBTListItem
                title={option?.name + ''}
                description={option.description}
                titleStyle={{ textWrap: 'wrap' }}
                descriptionStyle={{ textWrap: 'wrap' }}
                descrptionComponent="small"
              />
            </MenuItem>
          );
        }}
        renderInput={params => {
          const selectedContent = multiple ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {(value as any[]).map((chip, index) => (
                <LBTChip
                  key={index}
                  label={sortedItems.find(item => item.id === chip)?.name ?? ''}
                  color="default"
                  onDelete={() => {
                    const array = value as Array<any>;
                    handleChange(array.filter(item => item !== chip));
                  }}
                  deleteIcon={
                    <div onMouseDown={event => event.stopPropagation()}>
                      <IconCrossComponent
                        size={IconsSize.SMALL}
                        style={IconsStyle.OUTLINE}
                        color={COLORS.getInstance().BLACK}
                      />
                    </div>
                  }
                />
              ))}
            </Stack>
          ) : (
            <LBTLabel
              variant="inputFormLabel"
              component="label"
              textAlign="left"
            >
              {sortedItems.find(item => item.id === value)?.label ?? ''}
            </LBTLabel>
          );

          return (
            <LBTTextField
              {...params}
              value={inputText}
              onChange={str => setInputText(str ?? '')}
              InputLabelProps={{ sx: { display: 'none' }, shrink }}
              variant={variant}
              label={label}
              required={required}
              sx={{
                '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
                  width: 32,
                  height: 32,
                  marginRight: '1px',
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: hasSearchIcon() ? (
                  <StyledIconComponentBox>
                    <IconSearchComponent
                      size={IconsSize.MEDIUM}
                      style={IconsStyle.OUTLINE}
                      color={COLORS.getInstance().BLACK}
                    />
                  </StyledIconComponentBox>
                ) : (
                  selectedContent
                ),
              }}
              helperText={
                error ? (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <IconWarningComponent
                      size={IconsSize.MEDIUM}
                      style={IconsStyle.OUTLINE}
                      color={COLORS.getInstance().ERROR_MAIN}
                    />
                    {helperText}
                  </Box>
                ) : (
                  helperText
                )
              }
            />
          );
        }}
        {...props}
      />
    </FormControl>
  );
};

export default LBTAutocomplete;
