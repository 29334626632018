import { ImageProps } from '.';

export default function Superhero({ width, height }: ImageProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.9871 144.451C79.0119 141.565 74.4348 137.088 72.3453 134.401C70.3552 132.013 69.5592 131.715 69.6587 129.227C69.9572 113.804 76.1264 93.9037 76.4249 93.8042C76.7234 93.8042 76.7234 94.3017 77.0219 94.8987C79.0119 99.0778 79.6089 99.1773 79.5094 99.9734C77.2209 116.988 80.5045 128.332 81.798 134.302C82.2955 136.789 83.191 141.665 83.9871 143.854C83.9871 144.053 84.0866 144.252 83.9871 144.451Z"
        fill="#59A5FF"
      />
      <path
        d="M101.798 122.162C101.897 122.262 101.897 122.063 112.843 140.172C113.738 141.565 113.838 141.665 113.34 141.864C112.246 142.361 111.251 143.157 110.156 143.655C104.684 145.943 106.773 145.645 105.38 141.864C104.783 140.471 100.504 122.66 101.798 122.162Z"
        fill="#59A5FF"
      />
      <path
        d="M93.7383 51.7145C94.1363 52.1125 93.9373 62.7593 99.6089 62.0628C99.7085 62.0628 100.305 61.8638 100.405 62.0628C100.504 62.2618 100.405 62.5603 100.206 62.5603C99.7085 62.8588 99.4099 63.3563 98.9124 63.6548C97.6189 64.5504 95.4298 64.7494 93.8378 63.1573C90.4547 59.9732 90.4547 55.1971 90.6537 50.5205C91.6487 51.1175 92.6438 51.615 93.7383 51.7145Z"
        fill="#59A5FF"
      />
      <path
        d="M109.46 89.0279C109.161 89.4259 108.664 89.2269 108.863 88.7294C109.758 85.8438 110.654 83.1572 110.952 81.0677C111.649 75.6945 110.753 72.4109 111.052 72.3114C111.549 72.2119 112.047 75.2965 112.146 76.192C112.544 81.9632 110.654 87.5353 109.46 89.0279Z"
        fill="#282828"
      />
      <path
        d="M113.937 65.0476C114.733 65.6446 116.425 68.7292 116.823 72.9083C117.221 77.3859 117.818 76.1919 114.634 78.6795C113.937 79.2765 113.738 77.4854 113.539 76.5899C109.858 61.2665 98.3154 51.9133 113.937 65.0476Z"
        fill="#59A5FF"
      />
      <path
        d="M127.171 111.019C127.569 113.805 134.037 122.561 135.529 123.954C135.828 124.253 135.231 124.351 133.838 126.143C132.544 127.834 128.863 130.819 127.768 131.814C123.689 135.297 122.097 137.187 121.898 135.098C118.415 97.3863 112.246 96.5903 114.634 96.8888C117.619 97.2868 121.798 96.3913 124.186 94.1027C125.48 92.9087 124.783 98.0828 144.783 109.924C146.276 110.819 138.315 120.968 137.221 122.361C137.022 122.56 136.823 122.56 136.624 122.361C129.559 114.6 127.868 111.119 127.171 111.019Z"
        fill="#59A5FF"
      />
      <path
        d="M95.6288 97.4855C95.7283 94.1024 96.0269 90.9183 99.1114 88.8288C103.191 86.1422 108.664 90.7193 111.052 95.1969C116.325 104.948 121.4 136.49 121.002 138.082C120.902 138.779 121.499 137.983 122.594 139.476C126.674 144.849 126.674 152.411 125.679 155.396C124.883 157.883 123.092 157.684 122.395 156.291C117.619 146.54 121.002 153.406 117.121 143.754C116.823 143.157 117.42 142.859 117.718 143.555C119.41 146.938 120.206 150.918 123.092 155.993C124.783 158.879 126.674 149.724 124.186 144.053C123.689 142.958 121.3 137.983 120.703 139.078C119.41 141.267 116.126 141.665 116.126 141.167C116.126 140.869 119.012 140.371 120.007 138.679C120.803 137.386 120.504 137.684 118.813 129.824C114.534 109.028 113.042 94.0029 103.49 89.6248C100.803 88.4307 97.3204 90.1223 96.7234 95.5949C96.2259 100.272 98.2159 108.132 99.7085 112.51C102.793 121.366 113.241 135.197 115.629 140.869C115.728 141.167 115.529 141.565 115.231 141.068C98.6139 116.092 96.8229 110.62 95.6288 97.4855Z"
        fill="#282828"
      />
      <path
        d="M94.5343 98.0824C93.0418 99.774 86.5742 99.575 83.9871 99.2765C76.8229 98.2814 73.5393 77.0874 75.3304 70.8187C78.8129 58.4804 79.211 47.2367 79.609 41.8635C80.206 32.8088 81.599 29.4257 81.6985 28.3312C82.0965 25.7441 87.2707 14.5003 87.7682 13.9033C88.7632 12.5103 89.0617 13.8038 91.9473 14.4008C93.7383 14.7988 95.5294 15.0973 94.2358 15.2964C92.7433 15.4954 90.0567 14.7988 88.8627 14.0028C88.1662 13.6048 88.3652 13.9033 85.8776 19.575C80.1065 32.8088 81.1015 38.1819 80.206 49.5252C78.813 67.1371 74.2358 71.4158 77.0219 84.6496C77.6189 87.5352 78.2159 91.7143 80.7035 95.9929C81.6985 97.6844 81.4995 97.9829 82.6936 98.1819C89.3602 99.575 93.9373 97.7839 94.5343 98.0824Z"
        fill="#282828"
      />
      <path
        d="M116.723 180.57C116.624 177.983 116.027 177.386 115.629 176.292C115.33 175.496 115.529 177.188 112.146 177.885C111.947 177.885 111.748 177.983 111.649 177.784C111.649 177.585 111.848 177.585 112.047 177.486C118.614 174.501 112.743 177.287 102.992 146.939C98.5144 133.008 97.6189 116.492 98.9124 116.691C100.007 116.79 97.3204 140.769 116.524 176.59C117.718 178.779 118.017 182.063 116.723 184.948C114.932 189.128 108.067 197.288 107.37 192.81C106.773 189.129 110.853 181.366 111.052 179.774C111.052 179.575 111.151 179.476 111.35 179.476C112.345 179.675 106.574 192.412 108.465 193.308C109.559 193.606 116.922 186.142 116.723 180.57Z"
        fill="#282828"
      />
      <path
        d="M110.455 177.486C109.659 177.784 95.3303 162.361 89.5592 150.62C86.1761 143.854 82.793 134.7 81.1015 116.988C81.1015 116.988 79.7084 99.6749 81.002 99.7744C81.798 99.7744 80.9025 102.759 82.0965 114.998C83.4895 130.322 85.5791 142.162 93.6388 155.496C100.206 166.342 110.355 176.889 110.455 177.486Z"
        fill="#282828"
      />
      <path
        d="M128.664 67.237C127.669 66.9385 127.47 67.038 126.972 67.834C125.878 69.8241 125.878 71.8141 125.679 72.0131C125.281 71.9136 125.38 71.5156 125.38 71.3166C125.181 65.3464 128.763 65.4459 128.863 66.9385C129.261 66.839 129.758 66.7395 130.156 67.1375C131.549 68.431 131.151 73.7047 130.952 75.2967C128.365 94.6997 115.629 99.3763 111.649 92.3116C111.45 91.9136 111.748 91.5156 112.146 92.0131C113.241 93.5056 114.534 94.5007 116.425 94.4012C130.057 93.5056 132.146 64.2519 128.962 68.1325C127.669 69.7246 127.37 71.1176 127.271 71.2171C126.773 72.2121 126.674 69.426 128.664 67.237Z"
        fill="#282828"
      />
      <path
        d="M93.7383 51.7147C87.2706 50.8192 84.9821 41.9635 91.3503 34.7993C101.101 23.7545 115.529 33.8043 112.047 32.9088C108.067 31.8142 102.296 27.7346 94.3353 33.2073C90.3552 35.9933 86.5741 43.1575 89.3602 48.1326C92.5443 53.8043 99.3104 49.3267 99.609 49.9237C99.808 50.4212 97.8179 51.1177 97.4199 51.3167C96.0269 51.9137 93.7383 51.7147 93.7383 51.7147Z"
        fill="#282828"
      />
      <path
        d="M95.5293 12.809C95.8278 11.3165 96.3254 9.92346 95.8278 8.43093C94.4348 4.05282 89.2607 5.94336 89.1612 11.416C89.1612 11.9135 89.3602 12.3115 89.0617 12.411C88.1661 12.61 88.4647 9.62496 88.6637 9.02794C91.1512 1.96326 99.3104 4.64983 95.9273 12.5105C95.8278 12.7095 95.7283 12.809 95.5293 12.809Z"
        fill="#282828"
      />
      <path
        d="M104.385 57.187C102.594 56.2915 102.694 52.4109 102.793 50.4208C102.893 49.2268 103.39 48.1323 103.788 46.9382C103.987 46.4407 104.286 46.3412 104.783 46.4407C106.574 46.9382 107.47 45.7442 107.171 44.4507C106.873 43.4556 106.773 43.4556 106.873 43.3561C106.972 43.1571 107.271 43.3561 107.47 43.4556C109.261 44.8487 107.47 48.3313 104.783 47.1372C104.485 46.9382 104.385 47.1372 104.286 47.4357C102.395 53.4059 104.286 55.8935 104.385 57.187Z"
        fill="#282828"
      />
      <path
        d="M95.1313 15.993C95.2308 15.595 95.5293 15.794 95.5293 15.993C95.5293 16.988 94.3353 20.8686 93.8378 25.7442C93.5393 28.5303 93.6388 30.4208 93.6388 30.8188C93.2408 33.8039 91.4497 26.6397 95.1313 15.993Z"
        fill="#282828"
      />
      <path
        d="M122.793 66.3413C122.893 66.5403 122.893 66.5403 122.694 66.7393C122.196 67.3363 120.902 72.5104 124.485 74.6C126.972 75.993 127.171 76.0925 127.569 76.0925C128.266 76.0925 128.067 76.6895 127.669 76.7891C126.574 77.0876 123.589 75.0975 122.694 74.003C120.206 71.0179 121.698 64.6497 122.793 66.3413Z"
        fill="#282828"
      />
      <path
        d="M121.599 73.9037C120.305 78.1823 113.241 82.9584 112.843 82.4609C112.544 82.0629 115.131 81.4658 121.002 73.9037C121.5 73.2071 121.599 73.5056 121.599 73.9037Z"
        fill="#282828"
      />
      <path
        d="M108.067 39.6745C107.768 39.2765 107.868 38.8785 108.465 39.177C111.052 40.57 111.549 46.4407 109.062 48.2317C108.564 48.6297 108.564 48.2317 108.664 48.1322C109.161 47.5352 110.753 43.1571 108.067 39.6745Z"
        fill="#282828"
      />
      <path
        d="M112.644 39.0778C110.057 32.1127 104.385 33.3067 104.485 32.4112C104.584 31.8142 108.266 32.5107 110.355 34.2022C112.544 36.0928 113.042 38.6798 112.644 39.0778Z"
        fill="#282828"
      />
      <path
        d="M100.703 8.72929C100.405 5.84372 98.0169 5.84372 98.3154 5.2467C98.6139 4.55019 100.703 5.64471 101.4 8.13228C102.097 10.7193 98.4149 13.7044 98.0169 13.2069C97.8179 12.8089 100.903 10.8188 100.703 8.72929Z"
        fill="#282828"
      />
      <path
        d="M111.748 43.0579C111.251 42.9584 112.345 41.7644 109.161 37.5853C108.564 36.8888 107.171 35.9932 106.873 35.7942C106.574 35.4957 106.674 35.0977 107.171 35.2967C111.649 36.7893 112.644 43.1574 111.748 43.0579Z"
        fill="#282828"
      />
      <path
        d="M123.689 70.6211C123.689 71.0191 123.589 71.4171 123.689 71.8151C123.689 71.9146 123.788 72.0142 123.689 72.0142C122.893 72.3127 121.997 64.7505 126.077 65.1485C126.674 65.1485 127.072 65.248 127.072 65.646C126.972 66.9395 123.689 64.253 123.689 70.6211Z"
        fill="#282828"
      />
      <path
        d="M98.9124 8.23314C98.9124 11.9147 95.4298 13.6063 96.5244 12.3127C99.4099 8.73065 98.2159 5.94458 96.1263 5.84508C95.7283 5.84508 95.6288 5.34756 96.1263 5.24806C98.3154 5.04906 99.0119 7.23811 98.9124 8.23314Z"
        fill="#282828"
      />
      <path
        d="M97.1214 15.396C96.7234 15.396 95.5293 15.2965 95.6289 14.8985C95.7284 14.3015 96.9224 14.8985 97.9174 14.401C101.002 12.809 100.604 10.9184 101.002 11.6149C101.699 12.61 99.3104 15.595 97.1214 15.396Z"
        fill="#282828"
      />
      <path
        d="M94.2358 46.2417C95.4299 45.8437 96.5244 45.2467 97.4199 44.3511C97.7184 44.0526 98.1164 44.1521 98.1164 44.4506C98.1164 45.4457 98.3154 46.3412 97.6189 47.2367C96.9224 48.1322 95.0319 48.0327 95.1314 47.7342C95.2309 47.3362 96.2259 47.6347 97.1214 46.9382C97.8179 46.3412 98.0169 44.6496 97.5194 45.1472C96.8229 45.6447 94.6338 46.9382 94.2358 46.2417Z"
        fill="#282828"
      />
      <path
        d="M94.7333 43.7541C94.0368 43.9531 93.1413 42.7591 93.4398 42.0626C93.8378 41.0675 94.9323 40.4705 96.0268 38.8785C96.4248 38.381 97.3204 38.6795 94.8328 41.167C93.7383 42.3611 93.7383 42.3611 94.6338 43.6546C94.7333 43.6546 94.7333 43.6546 94.7333 43.7541Z"
        fill="#282828"
      />
      <path
        d="M98.8129 33.8038C98.9124 34.2018 98.6139 34.6993 98.1164 34.3013C96.9223 33.3063 94.2358 33.6048 95.4298 32.9083C96.2258 32.5103 98.4149 32.6098 98.8129 33.8038Z"
        fill="#282828"
      />
      <path
        d="M103.987 39.2768C103.987 39.3763 103.987 40.1723 103.589 40.1723C103.291 40.1723 103.888 39.1773 102.097 37.4857C100.803 36.2917 103.987 36.2917 103.987 39.2768Z"
        fill="#282828"
      />
      <path
        d="M93.4398 38.4807C92.4448 37.3862 91.2507 38.1822 91.0517 37.7842C90.9522 37.4857 92.0468 36.7892 93.0418 37.1872C94.4348 37.7842 93.9373 39.0777 93.4398 38.4807Z"
        fill="#282828"
      />
      <path
        d="M100.504 41.7644C100.504 42.3614 100.206 42.5604 99.9074 42.1624C99.0119 40.9684 98.2159 41.7644 98.2159 40.9684C98.2159 40.3714 100.405 40.1724 100.504 41.7644Z"
        fill="#282828"
      />
      <path
        d="M73.6388 39.7751C73.8378 34.2029 75.4298 28.3323 75.9273 28.5313C76.4248 28.7303 74.4348 30.8198 74.7333 43.7552C74.7333 44.7502 73.1413 44.5512 73.6388 39.7751Z"
        fill="#282828"
      />
      <path
        d="M131.35 147.436C132.146 147.337 132.345 148.531 132.246 149.327C131.848 153.804 127.868 158.978 127.47 158.481C127.37 158.381 130.853 153.307 131.052 149.028C131.151 148.133 130.952 147.536 131.35 147.436Z"
        fill="#282828"
      />
      <path
        d="M132.146 156.59C132.047 156.391 132.047 156.59 133.738 152.71C133.937 152.312 134.136 151.715 134.634 151.914C135.828 152.61 132.544 156.789 132.146 156.59Z"
        fill="#282828"
      />
      <path
        d="M71.3502 39.9734C71.3502 40.5704 70.7532 40.5704 70.5542 40.0729C70.2557 38.9784 71.1512 34.4012 71.2507 34.3017C72.0468 33.5057 71.3502 33.6052 71.3502 39.9734Z"
        fill="#282828"
      />
      <path
        d="M124.384 39.5751C121.797 38.6795 121.399 38.4805 121.399 37.983C121.299 37.2865 124.086 36.59 124.782 36.391C125.479 36.2915 125.578 31.7144 126.374 31.7144C126.573 31.7144 126.673 31.8139 126.772 32.0129C128.265 35.6945 128.066 35.794 128.862 35.595C129.757 35.3959 130.653 35.1969 131.449 34.9979C132.145 34.7989 132.543 35.0974 132.046 35.8935C130.354 38.0825 129.956 37.784 130.255 38.58C132.245 43.7542 131.847 42.8586 127.966 40.4706C127.17 39.9731 127.17 41.6646 125.28 43.3561C125.081 43.4557 124.981 43.7542 124.782 43.6547C123.688 43.3561 125.379 39.9731 124.384 39.5751Z"
        fill="#9AE6C8"
      />
      <path
        d="M61.399 21.6648C61.797 21.0678 61.8965 20.4708 62.195 19.8738C62.2945 19.6748 62.195 19.5753 62.0955 19.4758C61.2995 18.5802 60.802 17.5852 59.9065 16.6897C59.6079 16.3912 59.4089 15.6947 60.802 16.0927C61.2995 16.2917 62.195 16.4907 63.4885 16.6897C63.8866 16.7892 63.9861 16.6897 64.0856 16.2917C64.4836 14.6997 65.3791 11.5156 65.8766 13.0081C67.1701 16.9882 65.2796 16.3912 69.8567 16.5902C71.5483 16.6897 69.3592 18.6798 67.8667 19.4758C67.2696 19.7743 67.5682 19.8738 67.9662 23.4559C67.9662 23.7544 68.1652 24.2519 67.7672 24.4509C67.3691 24.6499 67.1701 24.2519 66.9711 23.9534C63.8866 20.6698 65.4786 21.0678 62.195 22.9584C60.7025 23.7544 60.9015 22.3613 61.399 21.6648Z"
        fill="#FFA084"
      />
      <path
        d="M61.797 85.0481C62.2945 81.267 62.6925 82.262 59.2099 80.869C57.6179 80.272 60.3045 79.0779 61.996 78.7794C62.792 78.6799 62.8915 74.1028 63.5881 74.1028C63.7871 74.1028 63.8866 74.0033 65.2796 77.5854C65.8766 79.1774 72.8418 74.6003 67.5682 80.1725C67.1701 80.5705 67.2696 80.471 68.5632 83.9536C68.7622 84.5506 68.5632 84.9486 67.8667 84.4511C64.7821 82.262 64.7821 82.5605 64.4836 82.9585C63.389 84.6501 61.797 87.3366 61.797 85.0481Z"
        fill="#F7C4FB"
      />
      <path
        d="M128.961 56.0926C131.051 56.0926 131.051 59.2767 128.961 59.2767C126.872 59.1772 126.772 55.9931 128.961 56.0926Z"
        fill="#59A5FF"
      />
      <path
        d="M118.115 16.8887C118.115 18.9783 114.931 18.8788 114.931 16.7892C114.931 14.6002 118.115 14.5007 118.115 16.8887Z"
        fill="#59A5FF"
      />
      <path
        d="M70.5532 10.9186C68.4637 10.9186 68.5632 7.83398 70.4537 7.83398C72.4438 7.83398 72.6428 10.9186 70.5532 10.9186Z"
        fill="#59A5FF"
      />
      <path
        d="M68.0657 71.7147C66.4736 71.7147 66.4736 69.3267 68.0657 69.3267C69.7572 69.4262 69.7572 71.7147 68.0657 71.7147Z"
        fill="#59A5FF"
      />
      <path
        d="M57.9164 36.2918C57.9164 34.6997 60.2049 34.7992 60.2049 36.2918C60.3044 37.7843 57.9164 37.8838 57.9164 36.2918Z"
        fill="#59A5FF"
      />
      <path
        d="M65.9761 90.3215C64.583 90.3215 64.6825 88.2319 65.9761 88.2319C67.3691 88.2319 67.3691 90.3215 65.9761 90.3215Z"
        fill="#59A5FF"
      />
      <path
        d="M112.245 55.8935C112.245 54.5005 114.334 54.5005 114.334 55.794C114.334 57.187 112.245 57.2865 112.245 55.8935Z"
        fill="#59A5FF"
      />
      <path
        d="M120.404 17.6846C121.498 17.7841 121.399 19.3761 120.304 19.3761C119.21 19.3761 119.309 17.6846 120.404 17.6846Z"
        fill="#59A5FF"
      />
      <path
        d="M57.9164 58.481C59.0109 58.481 59.0109 60.1725 57.8169 60.1725C56.7224 60.1725 56.8219 58.481 57.9164 58.481Z"
        fill="#59A5FF"
      />
      <path
        d="M72.0457 20.8687C73.0407 20.8687 72.9412 22.4607 72.0457 22.4607C71.2497 22.3612 70.9512 20.8687 72.0457 20.8687Z"
        fill="#59A5FF"
      />
      <path
        d="M56.4239 22.0627C56.4239 22.9582 55.0308 23.0577 55.0308 21.9632C55.1303 21.1672 56.4239 21.0677 56.4239 22.0627Z"
        fill="#59A5FF"
      />
    </svg>
  );
}
