import { ImageProps } from '.';

export default function Hooray({ width, height }: ImageProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.515 20.9731C12.279 20.5001 11.963 20.1051 11.726 19.6321C11.489 19.1591 11.332 18.6851 11.095 18.2111C11.0505 18.1471 10.9879 18.0977 10.9152 18.0694C10.8426 18.041 10.7631 18.0349 10.687 18.0519C10.6109 18.0688 10.5415 18.108 10.4877 18.1645C10.4339 18.221 10.3982 18.2922 10.385 18.3691C10.4916 19.5272 10.9929 20.6136 11.805 21.4461C12.121 21.9201 12.831 21.3671 12.515 20.9731Z"
        fill="#FFA084"
      />
      <path
        d="M7.466 36.9112C6.77811 36.8796 6.08895 36.9327 5.414 37.0692C4.861 37.1392 4.861 38.0072 5.414 37.9362C6.13074 37.9341 6.84518 37.855 7.545 37.7002C8.018 37.6212 7.86 36.9902 7.466 36.9112Z"
        fill="#FFA084"
      />
      <path
        d="M105.381 87.4862C104.828 87.3282 104.276 87.8802 104.118 88.5862C104.022 88.8905 104.041 89.2195 104.172 89.5105C104.303 89.8014 104.537 90.0341 104.828 90.1642C105.381 90.3222 105.933 89.7702 106.091 89.0642C106.17 88.3542 105.933 87.6432 105.381 87.4862Z"
        fill="#282828"
      />
      <path
        d="M95.124 85.9081C94.571 85.7501 94.024 86.3021 93.861 87.0081C93.7651 87.3124 93.7844 87.6414 93.9153 87.9323C94.0462 88.2233 94.2796 88.456 94.571 88.5861C95.123 88.7441 95.683 88.1931 95.834 87.4861C95.9415 87.1822 95.9277 86.8486 95.7955 86.5547C95.6632 86.2607 95.4227 86.0291 95.124 85.9081Z"
        fill="#282828"
      />
      <path
        d="M106.012 84.487C105.564 84.3672 105.091 84.3861 104.654 84.541C104.217 84.696 103.838 84.9798 103.566 85.355C103.334 85.675 103.566 86.223 103.96 86.065C104.421 85.9211 104.908 85.8819 105.386 85.9502C105.864 86.0185 106.32 86.1927 106.722 86.46C106.778 86.4923 106.84 86.5118 106.904 86.5171C106.969 86.5224 107.033 86.5134 107.094 86.4907C107.154 86.4681 107.208 86.4323 107.253 86.386C107.298 86.3397 107.332 86.2841 107.353 86.223C107.366 85.8229 107.239 85.4308 106.994 85.114C106.75 84.7973 106.402 84.5756 106.012 84.487Z"
        fill="#282828"
      />
      <path
        d="M94.256 83.541C94.2073 83.6177 94.1863 83.7088 94.1963 83.7991C94.2063 83.8895 94.2467 83.9737 94.311 84.038C94.3753 84.1022 94.4595 84.1427 94.5499 84.1527C94.6402 84.1627 94.7312 84.1416 94.808 84.093C95.1686 83.908 95.5799 83.8464 95.9789 83.9176C96.3779 83.9888 96.7426 84.1888 97.017 84.487C97.0728 84.5193 97.135 84.5388 97.1992 84.5442C97.2633 84.5495 97.3279 84.5406 97.3883 84.5181C97.4486 84.4955 97.5032 84.4598 97.5481 84.4137C97.593 84.3675 97.6271 84.3119 97.648 84.251C98.035 82.833 95.58 81.447 94.256 83.541Z"
        fill="#282828"
      />
      <path
        d="M103.724 93.3238C102.303 93.2448 97.724 94.7438 94.65 94.7438C94.5927 94.7342 94.534 94.7372 94.4779 94.7526C94.4219 94.768 94.3698 94.7954 94.3255 94.833C94.2811 94.8705 94.2455 94.9173 94.221 94.97C94.1966 95.0228 94.1839 95.0802 94.1839 95.1383C94.1839 95.1964 94.1966 95.2539 94.221 95.3066C94.2455 95.3593 94.2811 95.4061 94.3255 95.4436C94.3698 95.4812 94.4219 95.5086 94.4779 95.524C94.534 95.5394 94.5927 95.5424 94.65 95.5328C95.124 95.5328 95.676 95.6118 96.15 95.6118C96.15 95.6908 98.916 100.372 101.594 99.9518C104.439 99.5058 104.356 96.2428 104.356 93.9518C104.355 93.6398 104.039 93.3998 103.724 93.3238ZM100.962 98.6888C99.226 98.6168 98.201 96.9538 97.412 95.6118C97.964 95.6118 101.672 94.8228 103.012 94.6658C103.092 96.3998 103.013 98.7748 100.962 98.6888Z"
        fill="#282828"
      />
      <path
        d="M35.633 66.5768C35.554 66.1038 34.765 66.2618 34.844 66.8138C37.921 81.5678 47.231 94.1918 59.224 103.029C62.2299 105.313 65.4543 107.295 68.85 108.946C69.0867 108.561 69.3503 108.193 69.639 107.846C63.6278 104.624 58.0363 100.675 52.991 96.0858C44.4242 88.1743 38.3855 77.9084 35.633 66.5768Z"
        fill="#282828"
      />
      <path
        d="M57.8 126.857C51.496 122.882 46.1375 117.577 42.1 111.313C34.409 99.5259 30.255 87.2439 26.954 73.5999C26.9209 73.4973 26.8489 73.4117 26.7534 73.3616C26.6579 73.3115 26.5467 73.3009 26.4434 73.3319C26.3402 73.3629 26.2532 73.4331 26.2011 73.5275C26.1491 73.6219 26.1361 73.733 26.165 73.8369C27.9824 82.8902 30.7594 91.7241 34.449 100.189C39.591 111.445 46.529 121.848 57.173 127.962C57.883 128.277 58.514 127.251 57.8 126.857Z"
        fill="#282828"
      />
      <path
        d="M172.367 73.9152C172.537 73.2872 171.656 73.1262 171.42 73.6782C168.597 81.3942 164.849 89.2782 159.111 95.4552C159.664 95.3762 160.295 95.2972 160.926 95.2182C166.153 88.9719 170.046 81.7222 172.367 73.9152Z"
        fill="#282828"
      />
      <path
        d="M154.693 99.7939C149.471 104.244 143.407 107.598 136.862 109.657C136.727 109.708 136.616 109.807 136.551 109.936C136.487 110.065 136.473 110.214 136.512 110.352C136.552 110.491 136.643 110.609 136.766 110.684C136.889 110.759 137.036 110.785 137.177 110.757C144.243 108.604 150.757 104.943 156.271 100.027C155.719 99.9519 155.166 99.8729 154.693 99.7939Z"
        fill="#282828"
      />
      <path
        d="M177.811 82.7521C174.472 101.607 169.556 115.434 153.667 126.152C149.004 129.296 143.82 131.632 138.913 134.278C138.353 134.578 138.757 135.624 139.386 135.378C148.295 132.057 156.372 126.833 163.056 120.071C173.001 109.677 176.262 96.8851 178.679 82.9881C178.679 82.5151 177.894 82.2791 177.811 82.7521Z"
        fill="#282828"
      />
      <path
        d="M94.808 104.686C94.4081 106.095 94.2725 107.566 94.408 109.025C94.408 109.578 95.197 109.578 95.276 109.025C95.355 107.605 95.75 106.185 95.75 104.765C95.7335 104.657 95.6812 104.558 95.6014 104.483C95.5216 104.408 95.419 104.363 95.3102 104.354C95.2013 104.345 95.0926 104.372 95.0015 104.433C94.9104 104.493 94.8422 104.582 94.808 104.686Z"
        fill="#282828"
      />
      <path
        d="M35.791 65.7881C37.527 63.0271 38.147 58.4571 36.264 55.6101C34.598 53.0911 31.293 52.5331 28.453 52.2961C27.98 52.2171 27.823 53.0791 28.374 53.1641C30.746 53.5311 33.807 54.0391 35.238 56.2411C37.038 59.0121 35.949 62.6321 34.923 65.3941C34.765 65.9461 35.554 66.2621 35.791 65.7881Z"
        fill="#282828"
      />
      <path
        d="M25.3 72.337C23.705 71.5112 22.3265 70.3219 21.276 68.865C20.097 67.442 18.681 65.79 19.146 63.737C19.946 60.189 23.88 57.977 26.562 56.083C26.689 56.0052 26.7814 55.8816 26.8203 55.7378C26.8591 55.5939 26.8413 55.4407 26.7707 55.3095C26.7 55.1783 26.5818 55.0792 26.4404 55.0324C26.2989 54.9856 26.1449 54.9948 26.01 55.058C23.9517 56.1387 22.0857 57.5513 20.487 59.239C19.159 60.6 17.877 62.316 17.8 64.21C17.717 66.261 18.92 67.91 20.167 69.339C21.525 70.902 23.007 72.495 25.058 73.126C25.613 73.363 25.77 72.574 25.3 72.337Z"
        fill="#282828"
      />
      <path
        d="M18.038 57.1881C19.38 53.0881 22.911 50.6881 26.954 50.0081C27.504 49.9151 27.348 49.0561 26.796 49.0611C22.296 49.1011 18.354 52.7701 17.096 56.9511C17.086 57.0675 17.1177 57.1837 17.1855 57.2788C17.2533 57.374 17.3528 57.4419 17.4661 57.4704C17.5795 57.499 17.6992 57.4862 17.804 57.4345C17.9088 57.3827 17.9917 57.2954 18.038 57.1881Z"
        fill="#282828"
      />
      <path
        d="M175.365 60.1858C175.996 59.9498 175.756 58.9078 175.049 59.0858C171.796 59.8858 168.5 62.0858 167.633 65.5548C166.922 68.3958 168.422 71.6308 171.42 72.1828C171.545 72.2128 171.677 72.1922 171.787 72.1254C171.897 72.0587 171.976 71.9511 172.007 71.8263C172.039 71.7014 172.019 71.5693 171.953 71.4587C171.887 71.3481 171.781 71.268 171.656 71.2358C168.893 70.5358 167.915 67.3548 169.056 64.9238C170.28 62.2998 172.84 61.1328 175.365 60.1858Z"
        fill="#282828"
      />
      <path
        d="M178.679 63.4998C177.966 63.3558 177.732 64.3678 178.363 64.5258C180.024 64.927 181.601 65.6215 183.018 66.5768C183.7 67.1572 184.213 67.91 184.505 68.7567C184.796 69.6033 184.855 70.5127 184.675 71.3898C184.05 74.9418 180.912 77.4318 179.152 80.3898C178.826 80.9358 179.625 81.3358 179.941 80.8628C182.308 77.7858 185.898 74.4728 185.858 70.2898C185.821 66.2618 182.137 64.1998 178.679 63.4998Z"
        fill="#282828"
      />
      <path
        d="M176.627 57.1091C176.512 57.1091 176.402 57.1548 176.32 57.2362C176.239 57.3176 176.193 57.428 176.193 57.5431C176.193 57.6582 176.239 57.7685 176.32 57.8499C176.402 57.9313 176.512 57.9771 176.627 57.9771C178.626 57.9202 180.583 58.5573 182.165 59.78C183.747 61.0027 184.857 62.7355 185.306 64.6841C185.359 64.8168 185.459 64.9251 185.587 64.9881C185.715 65.0511 185.862 65.0641 185.999 65.0247C186.136 64.9852 186.254 64.8962 186.329 64.7748C186.404 64.6535 186.432 64.5085 186.406 64.3681C185.977 62.1555 184.735 60.1835 182.926 58.8402C181.116 57.4968 178.869 56.8793 176.627 57.1091Z"
        fill="#282828"
      />
      <path
        d="M175.128 50.8762C175.119 51.4282 175.928 51.4292 175.996 50.8762C176.176 49.4582 176.548 44.0912 176.943 42.9072H177.811C177.732 44.1702 177.732 50.2452 177.811 52.4542C177.023 52.3606 176.224 52.3872 175.444 52.5332C174.891 52.6122 175.049 53.4802 175.601 53.4802C180.095 53.4802 182.013 54.8262 184.675 58.2932C184.983 58.6932 185.853 58.4532 185.622 57.8982C185.001 56.6101 184.096 55.4794 182.976 54.5912C181.855 53.7031 180.548 53.0804 179.152 52.7702C179.152 51.1132 178.994 43.8542 178.757 42.3552C178.757 42.1972 176.706 41.6452 176.157 42.1182C175.68 42.4342 175.168 48.5872 175.128 50.8762Z"
        fill="#282828"
      />
      <path
        d="M19.38 34.8591C16.539 35.0961 13.773 35.5371 11.016 35.9591C10.542 36.0321 10.543 36.7481 11.016 36.6691C13.857 36.3531 16.697 36.2751 19.537 35.8011C20.011 35.6481 19.932 34.7801 19.38 34.8591Z"
        fill="#FFA084"
      />
      <path
        d="M37.29 32.7288C33.424 33.0448 29.478 33.5908 25.613 34.2288C25.139 34.3068 25.218 35.0968 25.692 35.0968C29.558 34.7808 33.503 34.3868 37.369 33.7558C37.921 33.5998 37.842 32.6498 37.29 32.7288Z"
        fill="#FFA084"
      />
      <path
        d="M23.64 32.3349C26.5451 29.5794 29.2852 26.655 31.846 23.5769C32.164 23.1839 31.609 22.6299 31.293 23.0239C28.532 25.8649 25.77 28.8629 23.093 31.7819C22.614 32.0999 23.24 32.7239 23.64 32.3349Z"
        fill="#FFA084"
      />
      <path
        d="M18.748 32.2559C18.7791 32.3077 18.8201 32.3528 18.8687 32.3888C18.9173 32.4247 18.9725 32.4508 19.0311 32.4654C19.0898 32.48 19.1507 32.4829 19.2105 32.474C19.2703 32.4651 19.3277 32.4445 19.3795 32.4134C19.4313 32.3822 19.4765 32.3412 19.5124 32.2926C19.5484 32.244 19.5744 32.1889 19.589 32.1302C19.6037 32.0716 19.6066 32.0106 19.5977 31.9509C19.5887 31.8911 19.5681 31.8337 19.537 31.7819C17.976 29.0899 16.302 26.4959 14.567 23.8919C14.251 23.4979 13.62 23.8129 13.857 24.2869C15.435 26.9689 17.064 29.6699 18.748 32.2559Z"
        fill="#FFA084"
      />
      <path
        d="M14.093 30.4412C11.256 29.6412 8.334 28.8632 5.493 27.9162C5.02 27.7582 4.859 28.4752 5.256 28.6262C8.06382 29.6992 10.9361 30.5953 13.856 31.3092C14.409 31.3882 14.646 30.6002 14.093 30.4412Z"
        fill="#FFA084"
      />
      <path
        d="M21.668 26.5748C21.825 23.6558 22.023 20.7368 21.983 17.8168C21.983 17.7017 21.9373 17.5913 21.8559 17.5099C21.7745 17.4285 21.6641 17.3828 21.549 17.3828C21.4339 17.3828 21.3235 17.4285 21.2421 17.5099C21.1607 17.5913 21.115 17.7017 21.115 17.8168C20.879 20.7361 20.7213 23.6555 20.642 26.5748C20.721 27.2058 21.668 27.2058 21.668 26.5748Z"
        fill="#FFA084"
      />
      <path
        d="M33.029 29.2568C36.1082 27.8052 39.0409 26.0616 41.787 24.0498C42.261 23.6558 41.86 22.8558 41.314 23.1818C38.361 24.9418 35.554 26.7328 32.556 28.4678C32.082 28.7048 32.483 29.5068 33.029 29.2568Z"
        fill="#FFA084"
      />
      <path
        d="M33.66 21.289C34.213 20.657 34.779 20.038 35.317 19.395C35.642 19.008 35.089 18.455 34.765 18.843C34.227 19.486 33.66 20.105 33.108 20.736C33.0666 20.771 33.033 20.8142 33.0091 20.8629C32.9853 20.9115 32.9718 20.9646 32.9696 21.0188C32.9673 21.0729 32.9763 21.1269 32.9959 21.1774C33.0156 21.2279 33.0455 21.2738 33.0838 21.3122C33.1221 21.3505 33.1679 21.3805 33.2184 21.4003C33.2688 21.42 33.3228 21.4291 33.377 21.4269C33.4311 21.4247 33.4842 21.4114 33.5329 21.3876C33.5817 21.3639 33.6249 21.3303 33.66 21.289Z"
        fill="#FFA084"
      />
      <path
        d="M170.315 42.6698C171.713 42.6747 173.1 42.4344 174.415 41.9598C174.892 41.7298 174.573 41.0918 174.178 41.0918C172.724 41.1721 171.276 41.3303 169.839 41.5658C168.371 41.7256 166.896 41.8048 165.42 41.8028C164.868 41.8028 164.868 42.5918 165.42 42.6698C167.05 42.7734 168.685 42.7734 170.315 42.6698Z"
        fill="#FFA084"
      />
      <path
        d="M192.092 44.4849C190.198 43.7749 188.304 43.2229 186.411 42.6699C184.521 42.0193 182.598 41.4665 180.651 41.0139C180.179 40.9269 179.862 41.5659 180.335 41.8029C182.15 42.5919 184.035 43.1669 185.858 43.7749C187.823 44.4309 189.8 45.1329 191.776 45.5899C191.917 45.6169 192.064 45.5899 192.187 45.5144C192.309 45.4389 192.399 45.3202 192.439 45.1818C192.478 45.0433 192.465 44.895 192.401 44.7661C192.336 44.6372 192.226 44.5369 192.092 44.4849Z"
        fill="#FFA084"
      />
      <path
        d="M159.664 44.0121C160.485 44.0116 161.304 43.9322 162.11 43.7751C162.662 43.6961 162.741 42.8281 162.11 42.8281C161.242 42.9071 160.374 42.9861 159.585 43.0651C159.111 43.2231 159.19 44.0121 159.664 44.0121Z"
        fill="#FFA084"
      />
      <path
        d="M160.137 32.8078C163.937 34.1138 167.637 35.8008 171.337 37.3838C171.889 37.6198 172.447 36.8208 171.889 36.5158C168.213 34.6261 164.353 33.1182 160.37 32.0158C159.824 31.8518 159.665 32.6458 160.137 32.8078Z"
        fill="#FFA084"
      />
      <path
        d="M175.68 37.5422C175.725 37.6745 175.82 37.7841 175.944 37.8477C176.069 37.9113 176.213 37.9239 176.347 37.8827C176.48 37.8416 176.592 37.75 176.659 37.6274C176.727 37.5048 176.743 37.3609 176.706 37.2262C175.831 33.6765 174.697 30.1958 173.313 26.8122C173.156 26.2592 172.274 26.5002 172.445 27.0482C173.541 30.5482 174.4 34.0772 175.68 37.5422Z"
        fill="#FFA084"
      />
      <path
        d="M180.257 38.7248C183.01 36.6102 185.94 34.7366 189.014 33.1248C189.566 32.8068 189.093 32.0178 188.541 32.2568C185.307 33.683 182.344 35.6582 179.783 38.0948C179.389 38.4098 179.854 39.0308 180.257 38.7248Z"
        fill="#FFA084"
      />
      <path
        d="M171.893 24.2078C171.55 23.0968 171.286 21.963 171.104 20.8148C171.028 20.2618 170.157 20.2628 170.236 20.8148C170.301 22.1104 170.567 23.3881 171.025 24.6018C171.262 25.0758 172.051 24.7598 171.893 24.2078Z"
        fill="#FFA084"
      />
      <path
        d="M194.3 28.6259C193.743 28.9175 193.215 29.261 192.722 29.6519C192.163 30.0369 191.696 30.3619 191.144 30.7569C191.055 30.8219 190.995 30.9186 190.976 31.0268C190.957 31.135 190.98 31.2465 191.041 31.338C191.102 31.4294 191.196 31.4939 191.303 31.5179C191.41 31.5419 191.523 31.5237 191.617 31.4669C192.169 31.1509 192.722 30.7569 193.274 30.3619C193.847 30.0684 194.378 29.6984 194.852 29.2619C195.248 28.9419 194.774 28.3899 194.3 28.6259Z"
        fill="#FFA084"
      />
      <path
        d="M179.941 33.6C181.36 30.288 182.994 27.0725 184.833 23.974C185.157 23.427 184.359 23.027 184.044 23.501C181.993 26.5626 180.349 29.8777 179.152 33.363C178.915 33.913 179.783 34.149 179.941 33.6Z"
        fill="#FFA084"
      />
      <path
        d="M98.832 85.2758C98.28 86.3808 96.058 90.2508 96.307 91.0358C96.3566 91.1518 96.4321 91.2548 96.5278 91.337C96.6235 91.4192 96.7368 91.4783 96.859 91.5098C97.6758 91.4765 98.479 91.2889 99.226 90.9568C99.936 90.7988 99.619 89.6068 98.911 89.7738C98.594 89.8478 98.201 90.0098 97.806 90.0888C98.501 88.6267 99.1071 87.1239 99.621 85.5888C99.7 85.1188 99.069 84.7998 98.832 85.2758Z"
        fill="#282828"
      />
      <path
        d="M99.305 104.055C96.938 103.187 94.412 103.033 92.046 102.161C87.783 100.59 94.019 82.8308 94.646 80.2268C94.725 79.7528 94.015 79.4378 93.857 79.9108C92.7398 82.703 91.8431 85.5785 91.175 88.5108C90.414 91.5168 89.675 94.5108 89.202 97.5848C88.886 99.6358 88.884 101.85 90.938 102.95C92.1834 103.474 93.4786 103.871 94.804 104.134C96.2487 104.568 97.7262 104.884 99.222 105.08C99.78 105.149 99.858 104.212 99.305 104.055Z"
        fill="#282828"
      />
      <path
        d="M115.322 90.8779C115.856 88.5773 115.99 86.2018 115.716 83.8559C115.593 82.946 115.276 82.0732 114.786 81.2967C114.296 80.5203 113.644 79.8583 112.876 79.3559C112.901 80.279 112.849 81.2027 112.718 82.1169C112.636 82.5899 111.929 82.6699 111.929 82.1169C111.853 80.7151 111.695 79.3191 111.456 77.9359C110.156 72.8879 105.17 65.0299 100.094 64.0489C96.615 63.3769 93.76 66.2489 92.678 69.2569C92.1324 70.7284 91.9766 72.316 92.2257 73.8654C92.4749 75.4149 93.1206 76.8736 94.1 78.0999C96.949 81.4059 101.832 81.7289 105.856 80.7819C106.733 82.8767 108.083 84.7401 109.801 86.2259C109.707 87.8956 109.496 89.5567 109.17 91.1969C108.933 92.1439 108.07 94.7459 108.933 95.5359C109.882 96.4019 111.3 94.0359 112.247 92.2229C116.271 89.5399 116.192 95.7729 112.878 96.3229C112.72 96.3229 109.597 105.623 109.091 107.448C108.83 108.389 110.432 108.395 110.984 108.395C111.536 108.395 111.537 107.685 111.063 107.527C110.76 107.386 110.443 107.281 110.116 107.212C110.905 103.74 112.405 100.663 113.588 97.3489C115.797 96.4809 117.849 93.7199 115.955 91.4319C115.783 91.2069 115.568 91.0185 115.322 90.8779ZM104.986 73.2049C103.486 71.2319 101.686 64.8589 98.201 66.9719C97.807 67.2099 97.182 66.6619 97.491 66.2619C100.725 62.0619 106.027 70.0469 106.327 72.8889C106.411 73.6779 105.3 73.7569 104.986 73.2049Z"
        fill="#282828"
      />
      <path
        d="M72.953 85.9872C71.7434 85.7151 70.6202 85.1474 69.6837 84.3348C68.7473 83.5223 68.0269 82.4903 67.587 81.3312C67.5596 81.2563 67.5149 81.1888 67.4567 81.1343C67.3985 81.0798 67.3283 81.0397 67.2517 81.0172C67.1752 80.9948 67.0945 80.9906 67.016 81.005C66.9375 81.0194 66.8636 81.052 66.8 81.1002C65.8073 81.9329 65.0237 82.9868 64.512 84.1772C64.4719 84.2351 64.4504 84.3038 64.4504 84.3742C64.4504 84.4446 64.4719 84.5133 64.512 84.5712C65.728 86.8852 68.3 90.5712 71.377 89.8582C71.377 89.8582 73.823 86.7812 73.901 86.7812C74.294 86.1442 73.505 85.5922 72.953 85.9872Z"
        fill="white"
      />
      <path
        d="M138.6 67.5239C138.048 67.4449 138.364 72.4159 136.865 73.9149C136.818 73.9607 136.783 74.0181 136.765 74.0814C136.747 74.1447 136.746 74.2117 136.762 74.2756C136.778 74.3394 136.81 74.398 136.856 74.4455C136.901 74.4929 136.959 74.5275 137.022 74.5459C137.966 74.9672 138.989 75.1824 140.022 75.1769C140.084 75.1849 140.148 75.1742 140.204 75.146C140.26 75.1179 140.306 75.0736 140.337 75.0189C141.82 73.0729 143.406 69.8829 141.679 68.1549C141.6 68.1549 138.676 67.5239 138.6 67.5239Z"
        fill="white"
      />
      <path
        d="M132.285 67.287C133.608 68.61 139.635 60.466 140.412 58.135C140.429 57.8433 140.402 57.5508 140.333 57.267C140.018 56.399 136.152 53.638 136.073 53.638C135.974 53.6196 135.873 53.6215 135.775 53.6437C135.677 53.6658 135.585 53.7077 135.503 53.7667C135.422 53.8258 135.354 53.9008 135.302 53.9871C135.251 54.0735 135.218 54.1694 135.205 54.269C134.688 55.7277 133.971 57.1075 133.074 58.369C131.974 59.713 128.574 62.077 128.735 62.55C129.731 64.2611 130.922 65.8508 132.285 67.287Z"
        fill="white"
      />
      <path
        d="M108.142 64.8411C108.253 64.9057 108.382 64.933 108.51 64.9187C108.638 64.9045 108.758 64.8496 108.852 64.7621C109.01 64.7621 110.189 62.9361 111.061 62.6321C111.988 62.494 112.934 62.5752 113.823 62.8691C114.217 63.0271 114.691 62.8691 114.691 62.3951C114.565 61.108 114.3 59.8384 113.902 58.6081C111.302 56.3991 107.353 57.8981 106.091 60.8171C105.7 61.6061 107.59 64.5261 108.142 64.8411Z"
        fill="white"
      />
      <path
        d="M56.936 46.9312C59.066 50.0872 63.564 51.8232 67.114 50.0872C67.5883 49.1858 67.9574 48.2329 68.214 47.2472C68.2431 47.1681 68.2512 47.0828 68.2373 46.9997C68.2235 46.9166 68.1882 46.8385 68.135 46.7732C68.056 46.6152 67.898 46.3792 67.661 46.3792C66.1321 46.3222 64.6181 46.0568 63.161 45.5902C61.7381 45.0352 60.4099 44.2626 59.224 43.3002C58.987 43.1422 57.958 44.1652 57.724 44.4832C57.2872 45.0587 56.9418 45.6983 56.7 46.3792C56.6888 46.4837 56.7046 46.5893 56.7459 46.686C56.7873 46.7826 56.8527 46.867 56.936 46.9312Z"
        fill="white"
      />
      <path
        d="M151.064 142.558C149.732 141.95 148.254 141.731 146.803 141.927C144.979 142.447 145.758 143.192 146.251 146.266C146.734 149.281 145.891 152.159 144.988 154.866C144.515 156.05 144.043 156.679 145.388 157.391C146.622 158.047 147.954 158.5 149.333 158.732C150.28 158.89 151.482 159.375 152.173 158.259C154.688 154.198 154.067 146.424 153.673 144.688C153.115 143.031 152.089 143.11 151.064 142.558Z"
        fill="white"
      />
      <path
        d="M177.574 65.394C177.416 66.494 177.455 71.077 177.416 71.394C177.377 71.711 177.007 73.055 176.864 72.42C176.776 72.027 176.864 66.1081 176.864 64.7661C176.864 64.2141 176.075 64.2141 175.996 64.7661C175.917 66.8181 175.917 68.866 175.917 70.921C175.917 71.946 175.444 73.6821 176.943 73.6821C178.521 73.6821 178.457 72.4211 178.521 71.2361C178.73 69.322 178.73 67.3911 178.521 65.477C178.363 65 177.653 65 177.574 65.394Z"
        fill="#282828"
      />
      <path
        d="M18.433 50.0082C22.315 47.6362 23.688 47.6612 27.822 47.5622C27.9342 47.557 28.0412 47.5135 28.1252 47.4389C28.2092 47.3644 28.2651 47.2633 28.2836 47.1525C28.3021 47.0417 28.282 46.928 28.2268 46.8302C28.1715 46.7324 28.0844 46.6565 27.98 46.6152C26.7119 46.3118 25.3933 46.2852 24.114 46.5372C23.798 45.0372 23.009 41.0142 22.772 38.3312C23.1631 38.3968 23.5597 38.4232 23.956 38.4102C24.035 39.5102 24.656 44.3282 24.824 45.5102C24.9 46.0622 25.849 45.9042 25.77 45.3522C25.692 44.0112 25.297 39.1192 25.06 38.0932C24.981 37.0682 21.753 37.1472 21.668 37.9352C21.522 39.2782 22.536 45.2732 22.851 46.6932C21.0237 47.1456 19.3239 48.0092 17.881 49.2182C17.7991 49.2993 17.7485 49.4068 17.7382 49.5216C17.7278 49.6364 17.7583 49.7511 17.8243 49.8456C17.8904 49.9401 17.9876 50.0082 18.099 50.038C18.2104 50.0677 18.3287 50.0572 18.433 50.0082Z"
        fill="#282828"
      />
      <path
        d="M28.059 67.8402C29.403 67.4552 28.994 66.3402 28.769 65.2402C28.352 63.1932 28.059 61.0582 27.669 59.0072C27.59 58.3762 26.564 58.6072 26.722 59.2432C26.88 60.5062 28.142 66.4232 27.59 66.6602C27.275 66.8182 25.906 61.0602 25.697 59.7962C25.606 59.2452 24.824 59.4812 24.908 60.0322C25.208 62.0072 25.529 63.9802 26.008 65.9502C26.317 67.2102 26.641 68.2452 28.059 67.8402Z"
        fill="#282828"
      />
      <path
        d="M136.625 90.5629C136.704 90.2469 136.625 89.9279 136.225 89.8529C134.492 89.5219 132.398 88.3529 132.125 86.4599C132.055 85.9849 131.809 85.5919 131.336 85.8289C130.815 86.1939 130.363 86.6478 130 87.1699C129.612 87.6204 129.293 88.1259 129.053 88.6699C128.817 89.2219 129.606 91.7469 130.553 92.6149C131.5 93.4829 133.945 94.1149 134.419 93.7149C135.307 92.7801 136.051 91.7177 136.625 90.5629ZM134.179 92.7719C133.214 92.7862 132.272 92.4795 131.5 91.8999C130.66 91.1527 130.149 90.1033 130.08 88.9809C130.389 88.6281 130.678 88.2593 130.948 87.8759L131.422 87.4029C131.736 88.2657 132.262 89.0357 132.951 89.6422C133.64 90.2488 134.471 90.6723 135.367 90.8739L134.179 92.7719Z"
        fill="#F7C4FB"
      />
      <path
        d="M79.9 67.84C79.8595 67.7597 79.7975 67.6921 79.7208 67.6449C79.6442 67.5977 79.556 67.5728 79.466 67.5728C79.376 67.5728 79.2878 67.5977 79.2111 67.6449C79.1345 67.6921 79.0725 67.7597 79.032 67.84C78.5019 68.8459 77.8368 69.7745 77.055 70.6C76.1612 71.4049 75.1148 72.0221 73.978 72.415C73.9 72.4403 73.8303 72.486 73.7758 72.5472C73.7214 72.6085 73.6843 72.6831 73.6683 72.7635C73.6523 72.8439 73.658 72.9271 73.6848 73.0045C73.7117 73.0819 73.7587 73.1508 73.821 73.204C73.978 73.598 75.004 75.255 75.321 75.729C75.3992 75.8383 75.5101 75.9201 75.6376 75.9625C75.7652 76.0049 75.9029 76.0058 76.031 75.965C78.556 75.413 81.475 73.283 81.79 70.521C81.8669 70.4187 81.9085 70.2941 81.9085 70.166C81.9085 70.038 81.8669 69.9134 81.79 69.811C81.079 69.181 80.527 68.471 79.9 67.84ZM78.953 73.205C78.1451 74.0419 77.1421 74.6652 76.034 75.019C75.395 73.955 75.707 74.529 74.771 73.126C75.8032 72.7922 76.7595 72.2582 77.5853 71.5548C78.4111 70.8513 79.0903 69.992 79.584 69.026C79.9008 69.4918 80.2433 69.9394 80.61 70.367C80.2611 71.4189 79.6953 72.3858 78.949 73.205H78.953Z"
        fill="#282828"
      />
      <path
        d="M35.87 124.49C35.8927 124.41 35.895 124.325 35.8766 124.244C35.8583 124.162 35.8199 124.087 35.7649 124.024C35.71 123.962 35.6402 123.914 35.562 123.885C35.4837 123.856 35.3995 123.847 35.317 123.859C34.923 123.78 32.95 123.622 32.398 123.622C32.2664 123.628 32.1407 123.678 32.0407 123.764C31.9407 123.849 31.8721 123.966 31.846 124.095C30.82 126.462 30.978 130.095 33.029 131.985C33.0779 132.11 33.1656 132.216 33.2793 132.287C33.3929 132.358 33.5264 132.391 33.66 132.38C34.564 132.326 35.4617 132.194 36.343 131.985C36.4279 131.986 36.5118 131.967 36.587 131.927C36.6622 131.888 36.7263 131.83 36.7734 131.759C36.8205 131.689 36.849 131.607 36.8565 131.523C36.8639 131.438 36.85 131.353 36.816 131.275C35.7822 129.173 35.4506 126.795 35.87 124.49ZM33.739 131.2C33.1376 130.276 32.6851 129.264 32.398 128.2C32.1779 127.073 32.2321 125.909 32.556 124.807C32.594 124.807 33.503 124.886 34.765 124.886C34.4533 125.915 34.3485 126.996 34.4568 128.066C34.5651 129.136 34.8843 130.173 35.396 131.119C34.844 131.117 34.292 131.2 33.739 131.2Z"
        fill="#F7C4FB"
      />
      <path
        d="M117.058 27.2059C116.427 27.4449 116.348 30.0459 116.742 31.1509C117.007 32.4769 117.725 30.8449 119.898 30.6779C120.886 30.6747 121.851 30.9779 122.66 31.5459C122.702 31.6009 122.759 31.6425 122.824 31.6654C122.89 31.6883 122.96 31.6915 123.028 31.6747C123.095 31.6579 123.155 31.6218 123.202 31.5708C123.249 31.5198 123.28 31.4562 123.291 31.3879C123.55 30.2938 123.866 29.214 124.238 28.1529C124.474 27.8369 122.429 25.1719 117.058 27.2059ZM122.817 30.6779C122.02 30.133 121.083 29.8284 120.118 29.8004C119.153 29.7724 118.2 30.0221 117.373 30.5199C117.229 29.6524 117.283 28.7635 117.531 27.9199C119.104 27.6579 121.131 26.2199 123.449 28.1569C123.174 28.9797 122.962 29.8225 122.817 30.6779Z"
        fill="#282828"
      />
      <path
        d="M93.624 44.4061C94.492 42.7492 88.084 41.0302 86.287 39.1202C84.3244 37.1563 83.0508 34.6095 82.657 31.8612C82.6846 31.737 82.6776 31.6076 82.6365 31.4871C82.5955 31.3667 82.5221 31.2599 82.4244 31.1784C82.3267 31.0969 82.2085 31.0439 82.0826 31.0251C81.9568 31.0064 81.8282 31.0227 81.711 31.0722C79.3792 32.2618 77.1607 33.6616 75.083 35.2542C72.868 36.7442 79.028 48.1931 85.261 50.8762C85.34 51.1131 86.761 51.9022 86.997 51.6652C89.6982 49.7456 91.9577 47.2706 93.624 44.4061ZM87.155 49.6932C81.474 46.5372 77.766 42.8282 76.345 36.1222C78.239 35.0222 79.345 33.6762 81.316 32.7292C81.632 35.5692 83.593 37.7882 85.498 39.9882C87.145 41.8882 89.285 42.9881 92.125 44.4061C90.6343 46.3175 88.9706 48.0873 87.155 49.6932Z"
        fill="#9AE6C8"
      />
      <path
        d="M134.5 108.315C124.874 111.392 112.171 107.684 112.171 107.684C112.171 107.684 108.456 117 99.7 115.022C91.019 113.058 92.835 107.684 92.835 107.684C79.817 110.603 70.98 106.027 70.98 106.027C65.773 111.708 59.224 134.037 59.224 134.037C63.8044 137.003 68.6051 139.614 73.584 141.848L72.4 178.221C98.28 186.347 126.526 180.666 126.526 180.666V160.784C124.635 163.017 122.994 165.451 121.634 168.042L115.874 159.521C116.818 157.197 118.262 155.108 120.102 153.404C121.943 151.7 124.136 150.421 126.526 149.659V144.846L135.915 141.059C139.86 131.67 134.5 108.315 134.5 108.315ZM75.714 133.958L75 129.776C75 129.776 78.708 130.959 81.391 126.699L83.442 130.249C83.367 130.249 80.133 135.22 75.714 133.958ZM88.338 111.866C82.9723 113.679 77.1683 113.734 71.769 112.024C71.6613 111.986 71.5719 111.909 71.5188 111.808C71.4657 111.707 71.4529 111.59 71.483 111.479C71.513 111.369 71.5837 111.275 71.6808 111.215C71.7778 111.155 71.8941 111.134 72.006 111.156C77.3071 112.212 82.789 111.914 87.944 110.288C88.969 109.893 89.372 111.492 88.338 111.866Z"
        fill="white"
      />
      <path
        d="M83.841 130.092C83.5458 128.655 82.8318 127.337 81.789 126.304C81.7731 126.209 81.7283 126.121 81.6606 126.052C81.5929 125.984 81.5056 125.938 81.4107 125.921C81.3159 125.903 81.218 125.916 81.1305 125.957C81.043 125.997 80.9702 126.064 80.922 126.147C80.3184 127.145 79.512 128.005 78.555 128.671C77.4734 129.272 76.222 129.493 75 129.3C74.763 129.142 74.29 129.457 74.369 129.773C74.606 131.114 75 132.456 75.158 133.873C75.1721 133.958 75.2061 134.038 75.2573 134.108C75.3084 134.177 75.3751 134.233 75.4521 134.271C75.5291 134.31 75.614 134.329 75.7 134.329C75.786 134.328 75.8707 134.307 75.947 134.267C78.787 135.367 82.18 133.167 83.679 130.796C83.758 130.717 83.758 130.638 83.758 130.48C83.8296 130.364 83.8589 130.227 83.841 130.092ZM80.133 132.537C79.5631 132.96 78.9062 133.25 78.2101 133.386C77.514 133.523 76.7962 133.502 76.109 133.326C75.9822 132.285 75.7708 131.256 75.477 130.249C77.687 130.486 80.527 129.381 81.477 127.33C82.1022 128.201 82.5326 129.197 82.739 130.249C81.9152 131.062 81.0451 131.825 80.133 132.537Z"
        fill="#282828"
      />
      <path
        d="M48.573 113.128C47.6712 111.772 46.5821 110.55 45.338 109.499C45.2152 109.434 45.0762 109.407 44.938 109.42C44.7763 109.408 44.614 109.435 44.465 109.499C41.395 111.014 38.784 113.286 35.155 111.945C34.839 111.313 33.971 111.866 34.129 112.418C34.444 114.312 35.155 116.205 35.629 118.099C35.708 118.336 36.102 118.651 36.181 118.73C40.681 120.308 45.57 117.468 48.569 114.154C48.7035 114.018 48.7793 113.834 48.78 113.642C48.7808 113.45 48.7065 113.266 48.573 113.128Z"
        fill="white"
      />
      <path
        d="M132.917 156.286C131.847 153.256 127.717 148.762 127.157 148.949C124.437 149.484 121.91 150.74 119.842 152.586C117.774 154.432 116.239 156.8 115.4 159.442C115.4 159.442 115.321 159.758 115.4 159.837C117.214 162.914 118.689 166.097 121.554 168.674C122.884 169.874 125.929 160.282 132.679 157.628C132.807 157.579 132.921 157.498 133.008 157.392C133.096 157.286 133.155 157.16 133.179 157.024C133.203 156.889 133.191 156.75 133.145 156.621C133.099 156.491 133.021 156.376 132.917 156.286ZM121.871 167.017C119.873 164.647 118.004 162.171 116.271 159.6C117.31 157.331 118.852 155.328 120.779 153.742C122.707 152.157 124.969 151.03 127.396 150.448C128.267 151.408 129.058 152.437 129.763 153.525C130.386 154.477 130.788 155.576 131.419 156.525C126.994 158.2 123.092 163.08 121.871 167.017Z"
        fill="#9AE6C8"
      />
      <path
        d="M161.478 94.981C160.828 95.1267 160.169 95.2323 159.506 95.297C157.376 95.5731 155.21 95.3295 153.194 94.587C153.054 94.54 152.903 94.5375 152.762 94.5797C152.621 94.622 152.496 94.707 152.405 94.823C151.675 95.7457 151.016 96.7218 150.432 97.743C149.961 98.612 153.274 99.706 154.772 99.952C156.832 100.4 158.979 100.207 160.926 99.399C161.084 99.399 161.242 99.242 161.326 99.005C161.562 97.979 161.799 97.032 162.036 96.005C162.267 95.3 162.031 94.9 161.478 94.981Z"
        fill="white"
      />
      <path
        d="M69.639 93.2452C70.112 93.0092 71.532 92.5352 71.69 92.4562C71.848 92.3772 72.558 92.3772 73.268 93.6402C73.8061 94.9155 74.1005 96.2804 74.136 97.6642C74.136 97.9792 72.874 98.6112 72.479 98.7642C72.084 98.9172 70.586 99.6322 70.901 98.6852C71.1491 97.6198 71.0094 96.5009 70.507 95.5292C70.112 94.9812 68.692 93.6402 69.639 93.2452Z"
        fill="white"
      />
    </svg>
  );
}
