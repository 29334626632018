import { IconsSize, IconsStyle } from '../../enums';

const IconArrowTopComponent = ({
  size,
  style,
  color,
}: {
  size: IconsSize;
  style: IconsStyle;
  color: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7367 16.9501C21.5014 17.0666 21.2154 16.9722 21.0978 16.7393C19.0002 12.5858 16.9281 10.3287 13.1723 8.24474C12.4436 7.84226 11.5561 7.84229 10.8275 8.24486C7.07175 10.3304 4.9997 12.586 2.9022 16.7393C2.78459 16.9722 2.49856 17.0666 2.26333 16.9501C2.02811 16.8337 1.93276 16.5505 2.05038 16.3176C4.22255 12.0164 6.42018 9.61097 10.3623 7.42209L10.3632 7.42163C11.3804 6.85946 12.6196 6.85946 13.6368 7.42163L13.6375 7.42202C17.5799 9.60939 19.7775 12.0165 21.9496 16.3176C22.0672 16.5505 21.9719 16.8337 21.7367 16.9501Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7959 15.6713C22.4813 16.9203 21.3216 18.2636 20.0824 17.9553C18.0584 17.4519 14.6513 16.7221 12 16.7221C9.34717 16.7221 5.94164 17.4519 3.91759 17.9553C2.67838 18.2636 1.51872 16.9203 2.20411 15.6713C4.27712 11.8929 6.55359 9.58211 10.2743 7.46011C11.3467 6.84663 12.6517 6.84663 13.7257 7.46011C17.4464 9.58054 19.7214 11.8913 21.7959 15.6697L21.7959 15.6713Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowTopComponent;
