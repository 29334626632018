import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  PageAction,
  Question,
  deletePageActionCallback,
  deleteQuestionCallback,
  getAllPageActionCallback,
  getAllQuestionCallback,
  getQuestionPageByIdCallback,
  pageActionsState,
  questionPageState,
  questionPagesState,
  questionsState,
} from '@laborability/commons';
import { useNavigate, useParams } from 'react-router-dom';
import { PageActionForm, QuestionForm } from '../components/Forms';
import { LBTButton, LBTSpacer } from '@laborability/components';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';

export function QuestionPagePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const getAllQuestion = useRecoilCallback(getAllQuestionCallback, []);
  const getAllPageAction = useRecoilCallback(getAllPageActionCallback, []);
  const deleteQuestion = useRecoilCallback(deleteQuestionCallback, []);
  const deletePageAction = useRecoilCallback(deletePageActionCallback, []);
  const getQuestionPage = useRecoilCallback(getQuestionPageByIdCallback, []);
  const questionPages = useRecoilValue(questionPagesState);
  const questionPage = useRecoilValue(questionPageState);
  const setAppBarName = useSetRecoilState(appBarState);
  const flowId = questionPage.flow_id;

  const {
    rowData: questionRowData,
    handleFetchAll: handleFetchAllQuestion,
    handleDelete: handleDeleteQuestion,
  } = useGrid<Question>({
    state: questionsState,
    onFetch: async () => {
      if (id) return await getAllQuestion({ id });
    },
    onDelete: deleteQuestion,
  });

  const {
    rowData: actionRowData,
    handleFetchAll: handleFetchAllPageAction,
    handleDelete: handleDeletePageAction,
  } = useGrid<PageAction>({
    state: pageActionsState,
    onFetch: async () => {
      if (id) return await getAllPageAction({ id });
    },
    onDelete: deletePageAction,
  });

  const questionColumnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'title',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'description',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
      valueFormatter: params => params.value ?? '-',
    },
    {
      field: 'display_order',
      flex: 4,
      filter: 'agNumberColumnFilter',
      headerName: 'Ordine',
      sortIndex: 0,
      sort: 'asc',
      hide: true,
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.QUESTION_CONDITION}/${data.data.id}`)
          }
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
    },
  ];

  const actionColumnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'default_target_page_id',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Pagina seguente',
      valueFormatter: params =>
        questionPages.find(page => page.id === params.value)?.name ?? '-',
    },
    {
      field: 'action_meta.display_order',
      flex: 4,
      filter: 'agNumberColumnFilter',
      headerName: 'Ordine',
      sortIndex: 0,
      sort: 'asc',
      hide: true,
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.ACTION_CONDITION}/${data.data.id}`)
          }
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
    },
  ];

  useEffect(() => {
    if (!questionPages.length) navigate(APP_ROUTES.QUESTIONARY);
  }, [questionPages]);

  useEffect(() => {
    if (id) getQuestionPage({ id });
  }, []);

  useEffect(() => {
    setAppBarName(
      `Pagina questionario${questionPage?.name ? ` - ${questionPage.name}` : ''}`,
    );
  }, [questionPage]);

  return (
    <>
      <GridLayout<Question>
        tableId="question_page"
        pageName="Domande"
        columnDefs={questionColumnDefs}
        rowData={questionRowData}
        handleFetchAll={handleFetchAllQuestion}
        handleDelete={handleDeleteQuestion}
        FormComponent={QuestionForm}
        recordNameAccessor={item => item.title ?? ''}
        tableHeight="60vh"
        gridFilters={
          <LBTButton
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/${APP_ROUTES.QUESTION_FLOW}/${flowId}`)}
          >
            Indietro
          </LBTButton>
        }
      />
      <LBTSpacer spacing={4} />
      <GridLayout<PageAction>
        tableId="action_page"
        pageName="Azioni"
        columnDefs={actionColumnDefs}
        rowData={actionRowData}
        handleFetchAll={handleFetchAllPageAction}
        handleDelete={handleDeletePageAction}
        FormComponent={PageActionForm}
        recordNameAccessor={item => item.name ?? ''}
        tableHeight="60vh"
      />
    </>
  );
}
