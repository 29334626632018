import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserState, TEST_DOMAINS } from '..';
import TagManager from 'react-gtm-module';
import md5 from 'md5';
import { useLocation } from 'react-router-dom';

interface DataLayerProps {
  key: string;
  value: string;
}

function useTrackAnalytics(
  props?: Array<DataLayerProps>,
  event?: 'page_view' | 'questionario',
) {
  const user = useRecoilValue(currentUserState);
  const domain = user.email?.substring(user.email?.indexOf('@') + 1);

  const triggered = useRef<boolean>(false);
  const location = useLocation();
  const storedLocation = useRef<string>('');

  useEffect(() => {
    if (
      location.pathname !== storedLocation.current ||
      location.pathname === '/questionary'
    ) {
      triggered.current = false;
      storedLocation.current = location.pathname;
    }

    const hasBeenTriggered = triggered.current;
    let dataLayer = {};

    if (!hasBeenTriggered) {
      dataLayer = {
        id_user: user.email ? md5(user.email) : null,
        user_test: domain
          ? TEST_DOMAINS.includes(domain)
            ? 'true'
            : 'false'
          : null,
        company:
          user.companies && user.companies.length > 0
            ? `company-${user.companies[0].id}`
            : null,
        company_name:
          user.companies && user.companies.length > 0
            ? user.companies[0].name
            : null,
        timestamp: new Date().toISOString(),
        is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
      };

      if (props)
        props.forEach((prop: DataLayerProps) => {
          Object.assign(dataLayer, { [prop.key]: prop.value });
        });

      if (props?.length === 0) return;
      console.log('@@@tagManager', dataLayer);
      TagManager.dataLayer({ dataLayer: dataLayer });

      triggered.current = true;
    }

    // eslint-disable-next-line
  }, [props]);
}

export default useTrackAnalytics;
