import { ButtonProps, styled } from '@mui/material';
import React from 'react';
import LBTRadio from '../Radio';
import LBTLabel from '../Label';
import { COLORS } from '../../utils';
import { StyledBox } from './Button';

const StyledRadio = styled(StyledBox)<
  ButtonProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ ...props }) => ({
  datatestid: props.datatestid,
  'data-track': props['data-track'],
  '&.MuiButton-root': {
    padding: '8px 7px 8px 15px',
  },
}));

export type LBTRadioButtonProps<T> = ButtonProps & {
  icon?: React.ReactNode;
  label: string;
  value: T;
  currentValue: T;
  handleChange: (val: T) => void;
  datatestid?: string;
};

export default function LBTRadioButton<T>({
  label,
  value,
  currentValue,
  handleChange,
  datatestid = '',
  ...props
}: LBTRadioButtonProps<T>) {
  return (
    <StyledRadio
      datatestid={`lbt-radio-${datatestid}`}
      data-track={datatestid}
      onClick={() => handleChange(value)}
      sx={{
        backgroundColor:
          currentValue === value
            ? COLORS.getInstance().PRIMARY_IPERLIGHT
            : COLORS.getInstance().WHITE,
      }}
      {...props}
    >
      <LBTLabel variant="inputFormLabel">{label}</LBTLabel>
      <LBTRadio value={value} onChange={() => {}} />
    </StyledRadio>
  );
}
