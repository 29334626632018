import { CallbackInterface } from 'recoil';
import {
  getUserById,
  getUserProfile,
  deleteUserProfile,
  getCompanyUsers,
  postUserRegister,
  acceptPrivacyTerms,
  getUserAnswersById,
  getUserAttributesById,
} from '../actions';
import { setSnackbarError } from '../common';
import {
  CalculatedAttributeMeta,
  FormattedAnswer,
  GetFilter,
  Id,
  UserAnswersFlow,
} from '../types';
import {
  currentUserState,
  userLoaderState,
  userState,
  usersLoaderState,
  usersState,
  userAnswersFlowState,
  calculatedAttributesMetaState,
} from '../state';
import { getDescriptionFromAnswer } from '../../utils';

const getAttributeValue = (value: string) => {
  if (value === 'true') return 'Sì';
  if (value === 'false') return 'No';
  return value;
};

export const postUserRegisterCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await postUserRegister();
      set(userState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const postUserAcceptPrivacyTermsCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await acceptPrivacyTerms();
      set(userState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserById(params);
      set(userState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserAnswersByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserAnswersById(params);
      const newdata: FormattedAnswer[] = [];
      (res?.data?.flows as UserAnswersFlow[]).forEach(flow => {
        flow.pages.forEach(page => {
          page.answers
            .slice()
            .sort((a, b) => a.entity_id - b.entity_id)
            .forEach(answer => {
              newdata.push({
                id: `${flow.flow_name}-${page.page_name}-${answer.label ?? answer.attribute_name}-${answer.answer}`,
                flow: flow.flow_name,
                page: page.page_name,
                question: answer.label ?? answer.attribute_name,
                answer: getDescriptionFromAnswer(
                  answer.answer,
                  answer.answer_type,
                ),
              });
            });
        });
      });
      set(userAnswersFlowState, newdata ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserAttributesByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserAttributesById(params);
      const newData: CalculatedAttributeMeta[] = [];

      (res?.data as CalculatedAttributeMeta[]).forEach(attribute => {
        newData.push({
          id: `${attribute.user_id}-${attribute.attribute_name}-${attribute.value}`,
          attribute_name: attribute.attribute_name,
          value: getAttributeValue(attribute.value!),
          user_id: attribute.user_id,
        });
      });
      set(calculatedAttributesMetaState, newData ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserProfileCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserProfile();
      set(currentUserState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getCompanyUsersCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id & GetFilter) => {
    set(usersLoaderState, true);
    let res = undefined;
    try {
      res = await getCompanyUsers(params);
      set(usersState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(usersLoaderState, false);
    return res;
  };

export const deleteUserProfileCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await deleteUserProfile(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };
