export interface Domain {
  id?: string | number;
  parent_id?: number;
  domain: string;
  value: string;
  description?: string;
  is_pinned?: boolean;
}

export class DomainClass implements Domain {
  id = 0;
  parent_id = undefined;
  value = '';
  domain = '';
  description = '';
  is_pinned = false;
}

export interface DomainKey {
  id?: string | number;
  domain: string;
  description: string;
}

export class DomainKeyClass implements DomainKey {
  description = '';
  domain = '';
}

export interface GetAllDomain {
  parent_id?: number;
}

export interface GetAllDomainKeys {
  domain_id?: string;
}

export interface PatchDomain {
  domain_key: string;
  new_domain_key: string;
  new_domain_description?: string;
}
