import { Question } from './question';

export type AttributeMeta = {
  id?: number;
  entity_type?: string;
  attribute_type?: string;
  attribute_name?: string;
  label?: string;
  questions?: Question[];
};

export type CalculatedAttributeMeta = {
  id?: string;
  user_id?: number;
  attribute_name?: string;
  value?: string;
};

export class AttributeMetaClass implements AttributeMeta {
  id = 0;
  entity_type = '';
  attribute_type = '';
  attribute_name = '';
  label = '';
  questions = [];
}

export interface GetAttributesMetaByEntityType {
  entity_type: string;
}

export const ATTRIBUTE_META_TYPES = ['number', 'string', 'bool'] as const;

export type AttributeMetaType = (typeof ATTRIBUTE_META_TYPES)[number];
