import { PageAction } from './pageAction';
import { Question } from './question';

export interface PageTips {
  type: string;
  title: string;
  message: string;
}

export interface QuestionPage {
  id?: number;
  flow_id: number;
  name?: string;
  hint?: string;
  description?: string;
  questions?: Question[];
  actions?: PageAction[];
  media_title?: string;
  media_subtitle?: string;
  media_description?: string;
  media_link?: string;
  flag_assocaf?: boolean;
  position?: number;
  help_alert_title?: string;
  help_alert_message?: string;
  entertainment_alert_title?: string;
  entertainment_alert_message?: string;
  tips?: PageTips[];
  page_order?: number;
}
export interface PostQuestionPage
  extends Omit<QuestionPage, 'questions' | 'actions'> {
  flow_id: number;
}

export class QuestionPageClass implements QuestionPage {
  id = 0;
  flow_id = 0;
  name = '';
  hint = '';
  description = '';
  questions = [];
  actions = [];
  media_title = '';
  media_subtitle = '';
  media_description = '';
  media_link = '';
  flag_assocaf = false;
  position = undefined;
  help_alert_title = '';
  help_alert_message = '';
  entertainment_alert_title = '';
  entertainment_alert_message = '';
  tips = [];
  page_order = undefined;
}

export interface ContinueFlowQuestion {
  question_id: number;
  entity_id: number;
  answer_value: any;
  question: Question;
  options: any;
}

export interface ContinueFlowResponse {
  flow_id: number;
  name: string;
  hint: string;
  flag_assocaf: boolean;
  description: string;
  media_link: string;
  media_title: string;
  media_subtitle: string;
  media_description: string;
  id: number;
  position: number;
  questions: ContinueFlowQuestion[];
  actions: PageAction[];
  has_question_rules?: boolean;
  tips?: PageTips[];
}

export class ContinueFlowResponseClass implements ContinueFlowResponse {
  flow_id = 0;
  name = '';
  hint = '';
  flag_assocaf = false;
  description = '';
  media_link = '';
  media_title = '';
  media_subtitle = '';
  media_description = '';
  id = 0;
  position = 0;
  questions = [];
  actions = [];
  tips = [];
}
