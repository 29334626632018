import Select, { SelectProps } from '@mui/material/Select';
import Box, { BoxProps } from '@mui/material/Box';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  CheckboxStyle,
  IconArrowDownComponent,
  IconArrowTopComponent,
  IconCrossComponent,
  IconWarningComponent,
  IconsSize,
  IconsStyle,
  LBTCheckbox,
  LBTListItem,
} from '../..';
import { COLORS } from '../../utils/Colors';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import LBTChip from '../Chip';
import LBTLabel from '../Label';
import { KeyValue } from '@laborability/commons';

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '12px',
      maxWidth: 'min-content',
      maxHeight: '300px',
    },
  },
  sx: { backgroundColor: 'transparent' },
};

export type LBTSelectProps = SelectProps & {
  items: (
    | (KeyValue & {
        description?: string;
        is_pinned?: boolean;
        [x: string]: any;
      })
    | undefined
  )[];
  handleChange: (e: any) => void;
  hasFullWidth?: boolean;
  helperText?: string;
  datatestid?: string;
  hasAsterisk?: boolean;
};

const StyledSelect = styled(Select)<
  SelectProps & {
    helperText?: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ helperText, datatestid, ...props }) => ({
  helperText,
  datatestid,
  'data-track': props['data-track'],
  '& .MuiSelect-root': {
    height: '52px',
  },
}));

const StyledIconComponentBox = styled(Box)<BoxProps>(() => ({
  width: '20px',
  height: '20px',
  marginRight: '16px',
}));

const LBTSelect: React.FC<LBTSelectProps> = ({
  items,
  handleChange,
  helperText,
  datatestid = '',
  variant = 'outlined',
  value,
  error,
  label,
  required,
  multiple,
  disabled,
  hasFullWidth = true,
  hasAsterisk = true,
  ...props
}: LBTSelectProps) => {
  const shrink = multiple
    ? !!(value as any[])?.length && (value as any[]).length > 0
    : value !== null && value !== undefined;
  const newItems: (KeyValue & { description?: string; is_pinned?: boolean })[] =
    items?.filter((item): item is KeyValue => !!item) || [];
  const [open, setOpen] = useState(false);
  const sortedItems = newItems.slice().sort((a, b) => {
    if (a.is_pinned && !b.is_pinned) return -1;
    if (!a.is_pinned && b.is_pinned) return 1;
    return 0;
  });
  return (
    <FormControl fullWidth={hasFullWidth}>
      <InputLabel required={!!label && required && hasAsterisk} shrink={shrink}>
        {label}
      </InputLabel>
      <StyledSelect
        size="small"
        notched={shrink}
        variant={variant}
        MenuProps={MenuProps}
        onChange={e => handleChange(e.target.value)}
        value={value === undefined ? null : value}
        label={label}
        fullWidth
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        IconComponent={() => (
          <StyledIconComponentBox>
            {open ? (
              <IconArrowTopComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().BLACK}
              />
            ) : (
              <IconArrowDownComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().BLACK}
              />
            )}
          </StyledIconComponentBox>
        )}
        renderValue={(selected: any) => {
          return multiple ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((chip: any, index: number) => (
                <LBTChip
                  key={index}
                  label={sortedItems.find(item => item.id === chip)?.name}
                  color="default"
                  onDelete={() => {
                    const array = value as Array<any>;
                    handleChange(array.filter(item => item !== chip));
                  }}
                  deleteIcon={
                    <div onMouseDown={event => event.stopPropagation()}>
                      <IconCrossComponent
                        size={IconsSize.SMALL}
                        style={IconsStyle.OUTLINE}
                        color={COLORS.getInstance().BLACK}
                      />
                    </div>
                  }
                />
              ))}
            </Stack>
          ) : (
            <LBTLabel
              variant="inputFormLabel"
              component="label"
              textAlign="left"
            >
              {sortedItems.find(item => item.id === selected)?.name ?? ''}
            </LBTLabel>
          );
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        required={required}
        multiple={multiple}
        error={error}
        style={{
          backgroundColor: disabled
            ? COLORS.getInstance().BW_GREYS_IPERLIGHT
            : undefined,
          height: 'auto',
          boxShadow: `0px 1px 8px 0px rgba(0, 0, 0, 0.12),
		                  0px 3px 4px 0px rgba(0, 0, 0, 0.14),
		                  0px 3px 3px -2px rgba(0, 0, 0, 0.20);`,
        }}
        disabled={disabled}
        {...props}
      >
        {!multiple && (
          <MenuItem
            key={'remove_item'}
            value={null}
            sx={required ? { display: 'none' } : {}}
          >
            <LBTLabel variant="listTitle">-</LBTLabel>
          </MenuItem>
        )}
        {sortedItems.map((option, index) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              borderTopWidth: index !== 0 ? '1px' : '0px',
              borderTopColor: COLORS.getInstance().BW_GREYS_JET_BLACK_LIGHT,
              borderTopStyle: 'solid',
              '&:hover': {
                backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
              },
              '&.Mui-selected': {
                backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT} !important`,
              },
            }}
          >
            {multiple && Array.isArray(value) && (
              <LBTCheckbox
                variant={CheckboxStyle.PRIMARY}
                checked={value.indexOf(option.id) > -1}
                sx={{ marginRight: '18px', padding: '11px' }}
                onChange={() => {}}
              />
            )}
            <LBTListItem
              title={option.name as string}
              description={option.description}
              titleStyle={{ textWrap: 'wrap' }}
              descriptionStyle={{ textWrap: 'wrap' }}
              descrptionComponent="small"
            />
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && (
        <FormHelperText sx={{ width: '100%' }}>
          {error ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IconWarningComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
              {helperText}
            </Box>
          ) : (
            helperText
          )}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default LBTSelect;
