import { CallbackInterface } from 'recoil';
import {
  AdvancedSearchMisura,
  GetAllMisura,
  GetFilter,
  GetMisuraByCategory,
  GetMisuraByTag,
  GetMisuraMostPopular,
  Id,
  Misura,
  Post,
  Put,
  SearchMisura,
} from '../types';
import {
  getAllMisure,
  getMisuraById,
  getMisureByTag,
  postMisura,
  putMisura,
  deleteMisura,
  searchMisura,
  getMisureMostPopular,
  getMisureByCategoryId,
  putStatusMisura,
  advancedSearchMisura,
  getUserEligibleMisure,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  measureLoaderState,
  measureState,
  measuresLoaderState,
  measuresState,
} from '../state';

export const postMisuraCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Misura>) => {
    set(measureLoaderState, true);
    let res = undefined;
    try {
      res = await postMisura(params);
      set(measureState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Misura caricata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureLoaderState, false);
    return res;
  };

export const getMisuraByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(measureLoaderState, true);
    let res = undefined;
    try {
      res = await getMisuraById(params);
      set(measureState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureLoaderState, false);
    return res;
  };

export const searchMisuraCallback =
  ({ set }: CallbackInterface) =>
  async (params: SearchMisura) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await searchMisura(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(
        measuresState,
        params.progressive ? current => [...current, ...items] : items,
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const advancedSearchMisuraCallback =
  ({ set }: CallbackInterface) =>
  async (params: AdvancedSearchMisura) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await advancedSearchMisura(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(
        measuresState,
        params.progressive ? current => [...current, ...items] : items,
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const getMisureByTagCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetMisuraByTag) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await getMisureByTag(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(
        measuresState,
        params.progressive ? current => [...current, ...items] : items,
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const getUserEligibleMisureCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await getUserEligibleMisure(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(measuresState, items);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const getMisureMostPopularCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetMisuraMostPopular) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await getMisureMostPopular(params);
      set(measuresState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const getMisureByCategoryIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetMisuraByCategory) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await getMisureByCategoryId(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(
        measuresState,
        params.progressive ? current => [...current, ...items] : items,
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const getAllMisureCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetAllMisura = {}) => {
    set(measuresLoaderState, true);
    let res = undefined;
    try {
      res = await getAllMisure(params);
      const items: Misura[] = res?.data?.items ?? [];
      set(
        measuresState,
        params.progressive ? current => [...current, ...items] : items,
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measuresLoaderState, false);
    return res;
  };

export const putMisuraCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Misura>) => {
    set(measureLoaderState, true);
    let res = undefined;
    try {
      res = await putMisura(params);
      set(measureState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Misura modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureLoaderState, false);
    return res;
  };

export const putStatusMisuraCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(measureLoaderState, true);
    let res = undefined;
    try {
      res = await putStatusMisura();
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureLoaderState, false);
    return res;
  };

export const deleteMisuraCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(measureLoaderState, true);
    let res = undefined;
    try {
      res = await deleteMisura(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Misura cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureLoaderState, false);
    return res;
  };
