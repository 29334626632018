import {
  CalculatedAttributeMeta,
  calculatedAttributesMetaState,
  FormattedAnswer,
  getUserAnswersByIdCallback,
  getUserAttributesByIdCallback,
  userAnswersFlowState,
} from '@laborability/commons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { ColDef } from 'ag-grid-community';
import { LBTButton, LBTSpacer } from '@laborability/components';
import { useGrid } from '../hooks/useGrid';
import { appBarState } from '../recoil/states/appBar';

export function UsersDetails() {
  const { id: user_id } = useParams();
  const navigate = useNavigate();
  const getAnswers = useRecoilCallback(getUserAnswersByIdCallback, []);
  const getAttributes = useRecoilCallback(getUserAttributesByIdCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const {
    rowData: attributesRowData,
    handleFetchAll: handleFetchAllAttributes,
  } = useGrid<CalculatedAttributeMeta>({
    state: calculatedAttributesMetaState,
    onFetch: async () => {
      if (user_id) return await getAttributes({ id: user_id });
    },
    onDelete: async () => {},
  });

  const { rowData: answersRowData, handleFetchAll: handleFetchAllAnswers } =
    useGrid<FormattedAnswer>({
      state: userAnswersFlowState,
      onFetch: async () => {
        if (user_id) return await getAnswers({ id: user_id });
      },
      onDelete: async () => {},
    });

  const answersColumnDefs: ColDef[] = [
    {
      field: 'flow',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Questionario',
    },
    {
      field: 'page',
      flex: 4,
      filter: 'agTextColumnFilter',
      headerName: 'Pagina',
    },
    {
      field: 'question',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Domanda',
    },
    {
      field: 'answer',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Risposta',
    },
  ];

  const attributesColumnDefs: ColDef[] = [
    {
      field: 'attribute_name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: "Nome dell'attributo",
    },
    {
      field: 'value',
      flex: 4,
      filter: 'agTextColumnFilter',
      headerName: 'Valore',
    },
  ];

  useEffect(() => {
    setAppBarName("Dati dell'utente");
  }, []);

  return (
    <>
      <GridLayout<any>
        tableId="user_answers"
        pageName="Risposte dell'utente"
        rowData={answersRowData}
        columnDefs={answersColumnDefs}
        handleFetchAll={handleFetchAllAnswers}
        tableHeight="60vh"
        FormComponent={() => null}
        recordNameAccessor={item => item.name ?? ''}
        hasAddNew={false}
        hasEdit={false}
        gridFilters={
          <LBTButton
            variant="outlined"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Indietro
          </LBTButton>
        }
      />
      <LBTSpacer spacing={4} />

      <GridLayout<any>
        tableId="action_page"
        pageName="Azioni"
        columnDefs={attributesColumnDefs}
        rowData={attributesRowData}
        handleFetchAll={handleFetchAllAttributes}
        FormComponent={() => null}
        recordNameAccessor={item => item.name ?? ''}
        tableHeight="60vh"
        hasAddNew={false}
        hasEdit={false}
      />
    </>
  );
}
