import {
  getQuestionFlowGraphCallback,
  questionFlowGraphState,
} from '@laborability/commons';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  Background,
  Controls,
  ReactFlow,
  Node,
  Edge,
  MiniMap,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  MarkerType,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

interface Props {
  flow_id: number;
}

export default function QuestionaryGraph({ flow_id }: Props) {
  const getGraph = useRecoilCallback(getQuestionFlowGraphCallback, []);
  const graph = useRecoilValue(questionFlowGraphState);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  useEffect(() => {
    getGraph({ id: flow_id });
  }, [flow_id]);

  useEffect(() => {
    const yOffset = 150;
    const xOffset = 250;
    let { nodes, edges } = graph.reduce<{
      nodes: Node[];
      edges: Edge[];
    }>(
      (prev, current) => ({
        nodes: [
          ...prev.nodes,
          {
            id: String(current.page_id),
            data: { label: current.name },
            position: { x: 0, y: 0 },
          },
        ],
        edges: [
          ...prev.edges,
          ...current.arcs.map(item => ({
            type: 'beezie',
            source: String(item.source_page_id),
            target: String(item.target_page_id),
            id: String(item.element_id),
            animated: true,
            label: item.name,
            markerEnd: {
              type: MarkerType.Arrow,
              width: 26,
              height: 26,
            },
          })),
        ],
      }),
      {
        nodes: [],
        edges: [],
      },
    );

    // set y
    for (let i = 0; i < nodes.length; i++) {
      for (let j = 0; j < edges.length; j++) {
        if (edges[j].target === nodes[i].id) {
          const parent = nodes.find(node => node.id === edges[j].source);
          if (parent && !parent.position.y)
            parent.position.y = nodes[i].position.y - yOffset;
        }
        if (edges[j].source === nodes[i].id) {
          const children = nodes.find(node => node.id === edges[j].target);
          if (children && !children.position.y)
            children.position.y = nodes[i].position.y + yOffset;
        }
      }
    }
    // set x
    for (let i = 0; i < nodes.length; i++) {
      let sameLevelNodes: Node[] = [];
      for (let j = 0; j < nodes.length; j++) {
        if (nodes[i].position.y === nodes[j].position.y)
          sameLevelNodes = [...sameLevelNodes, nodes[j]];
      }
      for (let j = 0; j < sameLevelNodes.length; j++) {
        sameLevelNodes[j].position = {
          y: sameLevelNodes[j].position.y,
          x: j * xOffset - ((sameLevelNodes.length - 1) * xOffset) / 2,
        };
      }
      sameLevelNodes = [];
    }

    setNodes(nodes);
    setEdges(edges);
  }, [graph]);

  return (
    <div style={{ height: '75vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        attributionPosition="top-right"
        className="overview"
      >
        <Controls />
        <MiniMap />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}
