import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { UserForm } from '../components/Forms';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { useGrid } from '../hooks/useGrid';
import {
  User,
  getAllCompaniesCallback,
  getCompanyUsersCallback,
  snackBarState,
  currentUserState,
  deleteUserProfileCallback,
  usersState,
  companiesState,
  SnackBarSeverity,
  UserRole,
  checkUserDetailsAccess,
} from '@laborability/commons';
import { appBarState } from '../recoil/states/appBar';
import { OpenInNew } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export function UsersPage() {
  const [companyId, setCompanyId] = useState<number>(0);
  const companies = useRecoilValue(companiesState);
  const navigate = useNavigate();
  const getAllCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const getCompanyUsers = useRecoilCallback(getCompanyUsersCallback, []);
  const deleteUser = useRecoilCallback(deleteUserProfileCallback, []);
  const setSnackBar = useSetRecoilState(snackBarState);
  const setAppBarName = useSetRecoilState(appBarState);
  const currentUser = useRecoilValue(currentUserState);

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'email',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Email',
    },
    {
      field: 'first_name',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'last_name',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Cognome',
    },
    {
      field: 'role',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Ruolo',
    },
    {
      field: 'open',
      headerName: 'Dettagli',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/${APP_ROUTES.USER}/${data.data.id}`)}
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
      hide: currentUser.role !== UserRole.ADMIN || !checkUserDetailsAccess(),
    },
  ];

  const fetchUsers = async () => {
    if (companyId) return await getCompanyUsers({ id: companyId });
    else
      setSnackBar({
        show: true,
        message:
          "Seleziona un'azienda dalla lista per visualizzarne gli utenti",
        severity: SnackBarSeverity.success,
        duration: 5000,
        code: 0,
      });
    return [];
  };

  const { rowData, handleFetchAll, handleDelete } = useGrid<User>({
    state: usersState,
    onFetch: fetchUsers,
    onDelete: deleteUser,
  });

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    companyId && handleFetchAll();
  }, [companyId]);

  useEffect(() => {
    setAppBarName('Registrazione utenti');
  }, []);

  return (
    <GridLayout<User>
      tableId="user"
      pageName="Utente"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={currentUser?.is_superuser ? handleDelete : undefined}
      FormComponent={UserForm}
      recordNameAccessor={item => `${item.first_name} ${item.last_name}`}
      hasAddNew={false}
      hasEdit={false}
      gridFilters={
        <FormControl>
          <InputLabel id="company-select-label">Seleziona azienda</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            name="company"
            label="Seleziona azienda"
            fullWidth
            onChange={async e => {
              setCompanyId(e.target.value as number);
            }}
            sx={{ width: '300px' }}
          >
            {companies.map((company, index) => (
              <MenuItem key={index} value={company.id}>
                {company.name} - {company.vat_number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  );
}
