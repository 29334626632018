import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  QuestionPageClass,
  questionPageState,
  getAllQuestionPageCallback,
  postQuestionPageCallback,
  putQuestionPageCallback,
  getQuestionPageByIdCallback,
  questionPageLoaderState,
  QuestionPage,
  questionPagesState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import {
  AlertEnum,
  LBTLabel,
  LBTSelect,
  LBTSpacer,
  LBTTextField,
} from '@laborability/components';
import { useParams } from 'react-router-dom';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function QuestionaryPageForm({ id, onClose }: Props) {
  const { id: flow_id } = useParams();
  const postPage = useRecoilCallback(postQuestionPageCallback, []);
  const putPage = useRecoilCallback(putQuestionPageCallback, []);
  const getPage = useRecoilCallback(getQuestionPageByIdCallback, []);
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const [current, setCurrent] = useRecoilState(questionPageState);
  const allQuestion = useRecoilValue(questionPagesState);

  const help_alert_title = current?.tips?.find(
    item => item.type === AlertEnum.help,
  )?.title;
  const entertainment_alert_title = current?.tips?.find(
    item => item.type === AlertEnum.entertainment,
  )?.title;
  const help_alert_message = current?.tips?.find(
    item => item.type === AlertEnum.help,
  )?.message;
  const entertainment_alert_message = current?.tips?.find(
    item => item.type === AlertEnum.entertainment,
  )?.message;

  const questionPageLoader = useRecoilValue(questionPageLoaderState);
  const isLoading = questionPageLoader;

  const resetFormState = () => {
    setCurrent({
      ...new QuestionPageClass(),
      help_alert_title: 'Aiuto! Dove lo trovo?',
      entertainment_alert_title: 'Lo sapevi?',
      help_alert_message: '',
      entertainment_alert_message: '',
    });
  };

  useEffect(() => {
    if (id) getPage({ id });
    else resetFormState();
  }, [id]);

  if (!flow_id) return null;

  return (
    <ModalForm<QuestionPage>
      defaultValues={{
        ...new QuestionPageClass(),
        help_alert_title: 'Aiuto! Dove lo trovo?',
        entertainment_alert_title: 'Lo sapevi?',
        help_alert_message: '',
        entertainment_alert_message: '',
      }}
      isLoading={isLoading}
      currentValues={{
        ...current,
        help_alert_title: help_alert_title,
        entertainment_alert_title: entertainment_alert_title,
        help_alert_message: help_alert_message,
        entertainment_alert_message: entertainment_alert_message,
      }}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome della pagina è obbligatorio' }
            : {}),
          ...(!values.page_order
            ? { page_order: 'Il campo è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        delete values.actions;
        delete values.questions;

        let tips: any[] = [];
        if (!values.help_alert_message) delete values.help_alert_title;
        else
          tips = [
            {
              type: AlertEnum.help,
              title: values.help_alert_title,
              message: values.help_alert_message,
            },
          ];
        if (!values.entertainment_alert_message)
          delete values.entertainment_alert_title;
        else
          tips = [
            ...tips,
            {
              type: AlertEnum.entertainment,
              title: values.entertainment_alert_title,
              message: values.entertainment_alert_message,
            },
          ];

        if (values.id) {
          await putPage({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
            flow_id: Number(flow_id),
            tips: tips.length ? tips : undefined,
          });
          return;
        }
        await postPage({
          ...removeNullishOrEmptyFields(values),
          flow_id: Number(flow_id),
          tips: tips.length ? tips : undefined,
        });
        return;
      }}
      resetGridState={() => getAllPage({ id: Number(flow_id) })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="name"
              onChange={value => setFieldValue('name', value)}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Sottotitolo"
              type="text"
              name="description"
              onChange={value => setFieldValue('description', value)}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="hint"
              onChange={value => setFieldValue('hint', value)}
              onBlur={handleBlur}
              value={values.hint}
              error={!!errors?.hint}
              helperText={errors?.hint}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="flag_assocaf-select"
              name="flag_assocaf"
              value={String(values.flag_assocaf)}
              label="Assocaf"
              required
              items={[
                { id: 'true', name: 'Sì' },
                { id: 'false', name: 'No' },
              ]}
              handleChange={e => {
                setFieldValue('flag_assocaf', e);
              }}
              onBlur={handleBlur}
              error={!!errors?.flag_assocaf}
              helperText={errors?.flag_assocaf}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="page_order-select"
              name="page_order"
              value={values.page_order ?? undefined}
              label="Ordine di visualizzazione"
              handleChange={e => setFieldValue('page_order', e)}
              onBlur={handleBlur}
              fullWidth
              required
              items={Array.from(
                Array(allQuestion.length + (values.id ? 0 : 1)).keys(),
              ).map(i => ({ id: i + 1, name: i + 1 }))}
              error={!!errors?.page_order}
              helperText={errors?.page_order}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSpacer spacing={1} />
            <LBTLabel variant="sourceSubtitle" textAlign="left">
              Sezione media
            </LBTLabel>
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="media_title"
              onChange={value => setFieldValue('media_title', value)}
              onBlur={handleBlur}
              value={values.media_title}
              error={!!errors?.media_title}
              helperText={errors?.media_title}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Sottotitolo"
              type="text"
              name="media_subtitle"
              onChange={value => setFieldValue('media_subtitle', value)}
              onBlur={handleBlur}
              value={values.media_subtitle}
              error={!!errors?.media_subtitle}
              helperText={errors?.media_subtitle}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="media_description"
              onChange={value => setFieldValue('media_description', value)}
              onBlur={handleBlur}
              value={values.media_description}
              error={!!errors?.media_description}
              helperText={errors?.media_description}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Link"
              type="text"
              name="media_link"
              onChange={value => setFieldValue('media_link', value)}
              onBlur={handleBlur}
              value={values.media_link}
              error={!!errors?.media_link}
              helperText={errors?.media_link}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSpacer spacing={1} />
            <LBTLabel variant="sourceSubtitle" textAlign="left">
              Alert hint
            </LBTLabel>
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="help_alert_title"
              onChange={value => setFieldValue('help_alert_title', value)}
              onBlur={handleBlur}
              value={values.help_alert_title}
              error={!!errors?.help_alert_title}
              helperText={errors?.help_alert_title}
            />
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="help_alert_message"
              onChange={value => setFieldValue('help_alert_message', value)}
              onBlur={handleBlur}
              value={values.help_alert_message}
              error={!!errors?.help_alert_message}
              helperText={errors?.help_alert_message}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSpacer spacing={1} />
            <LBTLabel variant="sourceSubtitle" textAlign="left">
              Alert entertainment
            </LBTLabel>
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="entertainment_alert_title"
              onChange={value =>
                setFieldValue('entertainment_alert_title', value)
              }
              onBlur={handleBlur}
              value={values.entertainment_alert_title}
              error={!!errors?.entertainment_alert_title}
              helperText={errors?.entertainment_alert_title}
            />
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="entertainment_alert_message"
              onChange={value =>
                setFieldValue('entertainment_alert_message', value)
              }
              onBlur={handleBlur}
              value={values.entertainment_alert_message}
              error={!!errors?.entertainment_alert_message}
              helperText={errors?.entertainment_alert_message}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
