import { Id } from './common';
import { RuleGroupType } from 'react-querybuilder';

export enum DestinationTypeEnum {
  target_page = 'target_page',
  next_step = 'next_step',
  menu = 'menu',
  home_page = 'home_page',
}

export interface ActionRule {
  id?: number;
  action_id?: number | string;
  name?: string;
  execution_order?: number;
  target_page_id?: number | null;
  schema?: RuleGroupType | null;
  destination?: DestinationTypeEnum;
  destination_step?: number;
}

export class ActionRuleClass implements ActionRule {
  id = 0;
  action_id = 0;
  name = '';
  execution_order = undefined;
  target_page_id = null;
  schema = null;
  destination = undefined;
  destination_step = undefined;
}

export interface PageAction {
  id?: number;
  page_id?: number;
  name?: string;
  action_type?: string;
  action_meta?: { [x: string]: unknown };
  default_target_page_id?: number | null;
  order?: number;
  rules?: ActionRule[];
  destination?: DestinationTypeEnum;
  destination_step?: number;
}

export class PageActionClass implements PageAction {
  id = 0;
  page_id = 0;
  name = '';
  action_type = 'button';
  action_meta = undefined;
  default_target_page_id = undefined;
  order = 0;
  rules = [];
  destination = undefined;
  destination_step = undefined;
}

export interface PostPageAction extends Omit<PageAction, 'rules'> {}

export interface GetActionRule extends Id {
  action_id: number | string;
}

export const ACTION_TYPES = ['button', 'skip', 'auto'] as const;

export enum ActionTypeEnum {
  button = 'button',
  skip = 'skip',
  auto = 'auto',
}

export type ActionType = (typeof ACTION_TYPES)[number];

export const ENTITY_TYPES = [
  'user',
  'child',
  'partner',
  'family',
  'property',
  'vehicle',
] as const;

export enum EntityTypeEnum {
  user = 'user',
  child = 'child',
  partner = 'partner',
  family = 'family',
  property = 'property',
  vehicle = 'vehicle',
}

export type EntityType = (typeof ENTITY_TYPES)[number];

export const ENTITY_ACTION_TYPES = ['loop_entity'] as const;

export type EntityActionType = (typeof ENTITY_ACTION_TYPES)[number];

export enum EntityActionTypeEnum {
  loop = 'loop_entity',
}

export const FLAG_ACTION_TYPES = ['add', 'edit'] as const;

export type FlagActionType = (typeof FLAG_ACTION_TYPES)[number];

export enum FlagActionTypeEnum {
  add = 'add',
  edit = 'edit',
}
