import { createContext } from 'react';

export interface QuestionaryContextProps {
  pageId: number;
  setPageId: (value: number) => void;
  step: number;
  setStep: (value: number) => void;
  isStepCompleted: boolean;
  setIsStepCompleted: (value: boolean) => void;
}

export const QuestionaryContext = createContext<QuestionaryContextProps>({
  pageId: 0,
  setPageId: () => {},
  step: 0,
  setStep: () => {},
  isStepCompleted: false,
  setIsStepCompleted: () => {},
});
